import React from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";

import girlHoldingBooks from "../images/student/girl-holding-books.png";
import selfie from "../images/teacher/selfie.png";

const Story = ({ className }) => {
  const { applicantType } = useParams();

  return (
    <div className={className}>
      <img
        src={applicantType === "student" ? girlHoldingBooks : selfie}
        alt=""
        className="rounded-md max-w-[1400px] max-h-[700px] mx-auto w-full object-cover object-bottom"
      />

      {applicantType === "student" && (
        <div>
          <h2 className="font-bold my-4 text-xl">
            Enthia's story as a student volunteer
          </h2>

          <p className="text-center max-w-[800px] mx-auto mb-10">
            {`Being a student volunteer with the Korean Canadian Association of Schools has been one of the most rewarding experiences of my life. I've had the opportunity to give back to my community and make a positive impact, and also gained valuable skills and experiences that will benefit me in the future.

From organizing events to working with other students and community members, I have learned so much about teamwork, communication, and leadership. I would highly recommend this opportunity to any student looking to make a difference and grow both personally and professionally.`}
          </p>
        </div>
      )}

      {applicantType === "teacher" && (
        <div className="max-w-[800px]  mx-auto">
          <h2 className="font-bold my-4 text-xl">
            Jason’s story as a KCSA teacher
          </h2>

          <p className="text-center max-w-[800px] mx-auto mb-10">
            {`Volunteering as a teacher with the Korean Canadian Association of Schools has been one of the most fulfilling experiences of my life. Not only have I been able to instill a sense of cultural pride in the next generation of Korean Canadian youth, but I've also had the privilege of being a mentor for many students who, like me, grew up feeling disconnected from their heritage. 

Being able to give back and make a positive impact in the lives of these young people has been incredibly inspiring and has truly changed me for the better.`}
          </p>
          <p>
            “I've also had the privilege of being a mentor for many students
            who, like me, grew up feeling disconnected from their heritage.”
          </p>
        </div>
      )}
    </div>
  );
};

export default styled(Story)``;
