import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import WhiteFooter from "../../footer/white-footer";
import ApplyNow from "./apply-now";
import ImagesSection from "./images-section";
import Main from "./main";
import Story from "./story";

import studentImage from "../images/student/main-image.png";
import teacherImage from "../images/teacher/teacher-image.png";

const StudentVolunteer = ({ className }) => {
  const { applicantType } = useParams();

  const navigate = useNavigate();

  const onClick = () => {
    applicantType === "student"
      ? navigate("/volunteer/student/collect-person-info")
      : navigate("/volunteer/teacher/collect-person-info");
  };

  return (
    <div className={`${className} mx-auto text-center mb-[100px] px-4`}>
      <h2 className="mt-16 lg:mt-[50px] text-3xl font-bold mx-auto">
        {`${
          applicantType === "student" ? "Student" : "Teacher"
        } Volunteer Opportunities`}
      </h2>
      <p className="text-center mt-4 mb-4">Be a part of something bigger.</p>

      <button
        onClick={onClick}
        className=" transition duration-200 ease-in-out cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500 z-10 mb-8"
      >
        Volunteer Today
      </button>

      <img
        src={applicantType === "student" ? studentImage : teacherImage}
        alt=""
        className="rounded-md max-w-[1400px] max-h-[700px] mx-auto w-full object-cover object-bottom"
      />

      <ImagesSection />

      <Story />

      <ApplyNow />
    </div>
  );
};

export default styled(StudentVolunteer)``;
