import React from "react";
import styled from "styled-components";
import Button from "../../common-component/next-back-button";
import { useNavigate } from "react-router-dom";
import CheckmarkAnimation from "../../Reusable/checkmark-animation";

const Success = ({ className, selectedSchool }) => {
  const navigate = useNavigate();

  return (
    <div className={className}>
      <div>
        <CheckmarkAnimation />
        <h1>Submitted</h1>
      </div>

      <div>
        <h4>Thank you for applying!</h4>
        <p>
          {`We appreciate your interest and look forward to reviewing your application.

An email confirming your submission has been sent to the email address you provided. If you do not receive an email confirmation within 24 hours, please check your spam folder or contact us at info@koreanschools.org for assistance.

We will carefully review all applications and will be in touch with all applicants soon. 

Best regards,
KCSA`}
        </p>
      </div>

      <Button name="Back to home" onClick={() => navigate("/")} />
    </div>
  );
};

export default styled(Success)`
  margin: auto;
  max-width: 385px;
  padding-top: 70px;
  width: 80%;
  margin: auto;
  white-space: pre-wrap;

  p:first-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;
    color: #191919;
    opacity: 0.8;
  }

  h1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    color: #000a23;
    box-sizing: border-box;
    height: 36px;
    margin: 0;
    margin-bottom: 25px;
    margin-top: 10px;
  }

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }

  > div:nth-of-type(2) {
    h4 {
      font-size: 18px;
    }

    p {
      margin-bottom: 25px;
    }
  }
`;
