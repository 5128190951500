const createSlice = require("@reduxjs/toolkit").createSlice;

const initialState = { user: null };

const checkData = () => {
  const localStorageData = localStorage.getItem(
    "sb-lqzahgakgtrswulxeedj-auth-token"
  );
  return localStorageData
    ? JSON.parse(localStorageData)?.user?.aud === "authenticated"
    : null;
};

const userSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    signIn: (state, { payload }) => {
      state.user = payload;
    },
    signOut: (state) => {
      state.user = null;
    },
    checkAuth: (state, { payload }) => {
      state.user = checkData();
    },
  },
});

export const { signIn, signOut, checkAuth } = userSlice.actions;
export default userSlice.reducer;
