import React, { useEffect, useState } from "react";
import GpsInput from "./gps-input";
import styled from "styled-components";
import { getDistanceFromLatLonInKm } from "./Helper";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import { useDispatch } from "react-redux";
import { setSchool } from "../../slices/studentRegistrationSlice";

export default function Schools({ setSelectedSchool, setPage, schools }) {
  const dispatch = useDispatch();

  const [coordinates, setCoordinates] = useState({
    lat: null,
    lng: null,
  });
  const [schoolsList, setSchoolsList] = useState(schools);

  // Ordering from closest to furthest based on user's input
  useEffect(() => {
    let sorted;
    if (coordinates.lat && coordinates.lng) {
      for (const school of schools) {
        school.distanceFrom = getDistanceFromLatLonInKm(
          coordinates.lat,
          coordinates.lng,
          school.latitude,
          school.longitude
        );
      }

      sorted = [...schools].sort((a, b) => {
        if (isNaN(a.distanceFrom) || isNaN(b.distanceFrom)) {
          return 0;
        } else if (isNaN(a.distanceFrom)) {
          return 1;
        } else if (isNaN(b.distanceFrom)) {
          return -1;
        } else {
          const diff = a.distanceFrom - b.distanceFrom;
          return diff === 0 ? 0 : diff > 0 ? 1 : -1;
        }
      });
    } else {
      sorted = [...schools].sort((a, b) => a.name.localeCompare(b.name));
    }

    setSchoolsList(sorted);
  }, [coordinates, schools]);

  // sm, md, lg, xl, 2xl
  return (
    <SelectSchool>
      <div className="pt-0 mx-auto">
        <h4 className="text-3xl lg:text-4xl font-bold leading-[1.2] text-center relative">
          School Registration
        </h4>
        <p className="text-center mt-4 lg:text-lg">
          To begin your registration, choose a school from our list below.
        </p>
      </div>

      <GpsInput coordinates={coordinates} setCoordinates={setCoordinates} />

      <div className="grid gap-4 max-w-[1400px] mt-8 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 mx-auto">
        {schoolsList.map((school) => (
          <div
            className="cursor-pointer transform hover:scale-105 hover:bg-gray-100 transition-transform duration-800 h-full"
            onClick={(e) => {
              e.preventDefault();
              dispatch(setSchool(school));
              setSelectedSchool(school);
              setPage("studentInformation");
            }}
            key={school.id}
          >
            <Card
              sx={{
                background: "white",
                p: 2,
                border: "1px solid #e4e4e4",
                height: "100%",
              }}
            >
              <CardContent>
                <Typography
                  variant="h5"
                  component="div"
                  style={{
                    fontSize: "clamp(14px, 2vw, 18px)",
                    fontWeight: 600,
                    minHeight: 50,
                  }}
                >
                  {school.name}
                </Typography>

                <Typography sx={{ fontSize: 13, mt: 1 }} color="text.secondary">
                  <span></span>
                  {school?.hoursOfOperation
                    ? Object.keys(school.hoursOfOperation).map((day) => (
                        <span key={school.name + day}>
                          <span>{day}</span> at {school.hoursOfOperation[day]}
                        </span>
                      ))
                    : "Schedule to be announced"}
                </Typography>

                <Typography sx={{ fontSize: 13, mt: 1 }} color="text.secondary">
                  <span>{`${school?.address}`}</span>
                </Typography>

                <Typography sx={{ fontSize: 13, mt: 1 }} color="text.secondary">
                  {school.city && (
                    <span>{`${school?.city}, ${
                      school?.province && school.province
                    } ${school?.postalCode ? school.postalCode : ""}`}</span>
                  )}
                </Typography>

                {school.address && coordinates.lng && (
                  <Typography sx={{ mt: 2 }}>
                    <span>
                      {`${parseFloat(school.distanceFrom).toFixed(1)} km away`}
                    </span>
                  </Typography>
                )}
              </CardContent>
            </Card>
          </div>
        ))}
      </div>
    </SelectSchool>
  );
}

const SelectSchool = styled.div``;
