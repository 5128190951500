const formatTimestamp = (timestamp) => {
  const year = parseInt(timestamp.slice(0, 4));
  const month = parseInt(timestamp.slice(5, 7));
  const day = parseInt(timestamp.slice(8, 10));

  const date = new Date(year, month - 1, day);

  const formatOptions = {
    weekday: "long",
    year: "numeric",
    month: "long",
    day: "numeric",
  };

  return date.toLocaleDateString("en-US", formatOptions).replace(",", "");
};

export { formatTimestamp };
