import React, { useState } from "react";
import styled from "styled-components";

// Redux
import Button from "../common-component/next-back-button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import NumericInputIcon from "@mui/icons-material/Filter9Plus";
import EmailIcon from "@mui/icons-material/Email";
import SupervisorAccountIcon from "@mui/icons-material/SupervisorAccount";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

export default function StudentInformation({
  className,
  setPage,
  register,
  trigger,
  errors,
  control,
  watch,
  setValue,
  loading,
}) {
  const [fullNameTouched, setFullNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);
  const [guardianRelationshipTouched, setGuardianRelationshipTouched] =
    useState(false);
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState("");

  const data = watch();

  const validateFullName = (value) => {
    if (!/^[a-zA-Z\s]*$/.test(value)) {
      return "Only letters and spaces are allowed";
    }
    if (!/\S+\s+\S+/.test(value)) {
      return "Please enter a first and last name";
    }
    return true;
  };

  const validateEmail = (value) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(value)) {
      return "Invalid email format";
    }
    return true;
  };

  const handleFullNameBlur = () => {
    setFullNameTouched(true);
  };

  const handleEmailBlur = () => {
    setEmailTouched(true);
  };

  const handlePhoneNumberBlur = () => {
    setPhoneNumberTouched(true);
  };

  const handleGuardianRelationshipBlur = () => {
    setGuardianRelationshipTouched(true);
  };

  const handlePhoneNumberChange = (event) => {
    const rawPhoneNumber = event.target.value;
    const digits = rawPhoneNumber.replace(/\D+/g, "");

    let newFormattedPhoneNumber;

    if (
      rawPhoneNumber.endsWith("-") &&
      rawPhoneNumber.length === formattedPhoneNumber.length - 1
    ) {
      newFormattedPhoneNumber = rawPhoneNumber.slice(0, -1);
    } else if (digits.length >= 6) {
      newFormattedPhoneNumber = `${digits.slice(0, 3)}-${digits.slice(
        3,
        6
      )}-${digits.slice(6)}`;
    } else if (digits.length >= 3) {
      newFormattedPhoneNumber = `${digits.slice(0, 3)}-${digits.slice(3)}`;
    } else {
      newFormattedPhoneNumber = digits;
    }

    setFormattedPhoneNumber(newFormattedPhoneNumber);
    setValue("guardianPhone", newFormattedPhoneNumber);
  };

  return (
    <div className="mt-4">
      <div className="pt-0">
        <h4 className="text-3xl lg:text-4xl font-bold leading-[1.2] text-center relative ">
          School Registration
        </h4>
        <p className="text-sm sm:text-base text-center mt-4 whitespace-pre-line break-word word-wrap px-2">{`Final Step!\nIf you're over 18, enter your own details or put 'Other' in the columns not applicable to you.`}</p>
      </div>

      <Mobile className="form bg-white p-8 rounded-lg mt-8 mx-auto drop-shadow-lg">
        <div>
          <p className="text-xs">School Registration - Step 4 of 4</p>
          <h1 className="section">Guardian Info</h1>
        </div>
        <TextField
          id="outlined-full-name"
          label="Guardian Name"
          variant="outlined"
          name="guardianName"
          onBlur={handleFullNameBlur}
          error={Boolean(errors.guardianName) && fullNameTouched}
          helperText={
            errors.guardianName &&
            fullNameTouched &&
            errors.guardianName.message
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
            inputProps: {
              ...register("guardianName", {
                required: "This field is required",
                validate: validateFullName,
              }),
            },
          }}
          placeholder="ex. Susan Kim"
          disabled={loading}
        />
        <TextField
          id="outlined-full-name"
          label="Relationship to Student"
          variant="outlined"
          name="guardianRelationship"
          onBlur={handleGuardianRelationshipBlur}
          error={
            Boolean(errors.guardianRelationship) && guardianRelationshipTouched
          }
          helperText={
            errors.guardianRelationship &&
            guardianRelationshipTouched &&
            errors.guardianRelationship.message
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SupervisorAccountIcon />
              </InputAdornment>
            ),
            inputProps: {
              ...register("guardianRelationship", {
                required: "This field is required",
              }),
            },
          }}
          placeholder="ex. Mother"
          disabled={loading}
        />
        <TextField
          id="outlined-full-name"
          label="Email"
          variant="outlined"
          name="guardianEmail"
          onBlur={handleEmailBlur}
          error={Boolean(errors.guardianEmail) && emailTouched}
          helperText={
            errors.guardianEmail && emailTouched && errors.guardianEmail.message
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <EmailIcon />
              </InputAdornment>
            ),
            inputProps: {
              ...register("guardianEmail", {
                required: "This field is required",
                validate: validateEmail,
              }),
            },
          }}
          placeholder="example@mail.com"
          disabled={loading}
        />
        <TextField
          name="guardianPhone"
          id="outlined-formatted-input"
          label="Phone Number"
          variant="outlined"
          value={data.guardianPhone}
          onBlur={handlePhoneNumberBlur}
          error={Boolean(errors.guardianPhone) && phoneNumberTouched}
          helperText={
            errors.guardianPhone &&
            phoneNumberTouched &&
            errors.guardianPhone.message
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <LocalPhoneIcon />
              </InputAdornment>
            ),
            inputProps: {
              ...register("guardianPhone", {
                required: "This field is required",
              }),
            },
          }}
          placeholder="### - ### - ###"
          onChange={handlePhoneNumberChange}
          disabled={loading}
        />
        {loading ? (
          <button
            className="transition duration-200 ease-in-out bg-transparent text-red-500 font-semibold px-4 rounded mr-2 mb-10 lg:mb-[100px] cursor-not-allowed relative"
            disabled
          >
            <span className="inset-0 flex items-center justify-center">
              <div className="animate-spin rounded-full h-4 w-4 border-t-2 border-red-500 mb-4"></div>
            </span>
            <p className="text-sm text-red-500">
              Thanks! One moment while we process your request.
            </p>
          </button>
        ) : (
          <div className="mt-5">
            <Button
              name="Submit"
              disabled={
                loading ||
                !data?.guardianName ||
                !data?.guardianEmail ||
                !data?.guardianPhone ||
                !data?.guardianRelationship ||
                data?.guardianName === "" ||
                data?.guardianEmail === "" ||
                data?.guardianPhone === "" ||
                data?.guardianRelationship === "" ||
                Object.keys(errors).length > 0
              }
            />
            <p
              className="text-sm text-center mt-4 cursor-pointer hover:underline text-grey"
              onClick={(e) => {
                e.preventDefault();
                setPage("address");
              }}
            >
              Back
            </p>
          </div>
        )}
      </Mobile>
    </div>
  );
}

const Mobile = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  max-width: 600px;
  width: 100%;
  margin-bottom: 50px;

  .Mui-error {
    margin: 0;
  }

  > div {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #191919;

    label {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #191919;
      opacity: 0.8;
    }

    > input {
      background: #f4f4f4;
      border-radius: 5px;
      border: none;
      box-sizing: border-box;
      height: 40px;
      width: 100%;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: black;
      opacity: 0.8;

      ::placeholder {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #707070;
        opacity: 0.8;
      }
    }
  }

  @media only screen and (min-width: 700px) {
    padding: 50px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  }
`;
