import React from "react";
import styled, { keyframes } from "styled-components";

import downArrow from "./images/down-arrow.png";
import mainSectionImage from "./images/main-section-image.mp4";
import mainSectionImageMobile from "./images/main-section-image-mobile.mp4";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const bounceAnimation = keyframes`
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-30px);
  }
  60% {
    transform: translateY(-15px);
  }
`;

const MainSection = ({ className }) => {
  const navigate = useNavigate();
  const screen = useSelector((state) => state.screen);

  const handleButtonClick = () => {
    const targetElement = document.querySelector(".about-us");

    if (targetElement) {
      targetElement.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <div className={`${className} mx-auto`}>
      <div className="mt-6 w-[100vw] max-w-[1000px] lg:mt-16 lg:mb-20 px-6 mx-auto">
        <h1 className="text-3xl lg:text-4xl xl:text-6xl 2xl:text-6xl font-bold leading-[1.2] text-center relative">
          Discover Korean with{" "}
          <span className="relative z-10">
            KCSA
            <span className="absolute inset-x-0 bottom-0.5 h-[12px] bg-red-200 block underline lg:bottom-2 lg:h-[20px]"></span>
          </span>
          !
        </h1>

        <p className="lg:text-lg xl:text-xl mt-5 mb-5 text-center">
          At the Korean Canadian Schools Association, we offer immersive Korean
          lessons for all levels, merging language and culture in a tailored
          experience.
        </p>
        <div className="text-center">
          <button
            className="transition duration-200 ease-in-out bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded mr-2 "
            onClick={handleButtonClick}
          >
            Learn More
          </button>

          <button
            onClick={() => navigate("/student-registration")}
            className="transition duration-200 ease-in-out cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500"
          >
            Register
          </button>
        </div>
      </div>

      {screen === "mobile" ? (
        <div
          dangerouslySetInnerHTML={{
            __html: `
            <video playsinline autoplay loop muted>
              <source
                src=${mainSectionImageMobile}
                alt="KCSA Korean Education School video"
                type="video/mp4"
              />
            </video>`,
          }}
          className="mx-auto order-last mt-10"
        />
      ) : (
        <div
          dangerouslySetInnerHTML={{
            __html: `
            <video playsinline autoplay loop muted>
              <source
                src=${mainSectionImage}
                alt="KCSA Korean Education School video"
                type="video/mp4"
              />
            </video>`,
          }}
          className="mx-auto order-last mt-10"
        />
      )}
    </div>
  );
};

export default styled(MainSection)`
  video {
    height: 70vh;
    width: 100vw;
    /* border-radius: 25px; */
    object-fit: cover;
    /* max-width: 1400px; */
    margin: auto;
  }

  @media screen and (min-width: 1024px) {
  }

  img.bounce {
    position: absolute;
    bottom: 25px;
    animation: ${bounceAnimation} 2s infinite;
  }

  .underline {
    z-index: -1;
  }
`;
