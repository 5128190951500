import React from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { supabase } from "../../server";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { useNavigate } from "react-router-dom";

const SignUp = ({ className }) => {
  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  console.log(watch("email"));
  console.log(watch("password"));

  const onSubmit = async (userData) => {
    console.log("signing up");
    const { data: usersData, error: usersError } = await supabase.auth.signUp({
      email: watch("email"),
      password: watch("password"),
      options: {
        emailRedirectTo: "http://localhost:3000/sign-in?confirmation=true",
      },
    });

    console.log(usersData);

    const { error: userError } = await supabase.from("user").insert({
      role: watch("role"),
      users_id: usersData.user.id,
    });

    navigate("/account-verification");
  };

  return (
    <div className={className}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="field">
          <Controller
            control={control}
            name="email"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                onChange={onChange}
                value={value}
                id="outlined-basic"
                label="Email"
              />
            )}
            rules={{
              required: "This field is required",
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "Format not accepted",
              },
            }}
          />
          {errors?.email?.message}
        </div>

        <div className="field">
          <Controller
            control={control}
            name="password"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                onChange={onChange}
                value={value}
                id="outlined-basic"
                label="Password"
              />
            )}
            rules={{
              required: "This field is required",
            }}
          />
          {errors?.password?.message}
        </div>

        <div className="field">
          <Controller
            control={control}
            name="repeatPassword"
            render={({
              field: { onChange, onBlur, value, name, ref },
              fieldState: { invalid, isTouched, isDirty, error },
              formState,
            }) => (
              <TextField
                id="outlined-basic"
                label="Repeat Password"
                onChange={onChange}
                value={value}
              />
            )}
            rules={{
              required: "This field is required",
            }}
          />
          {errors?.repeatPassword?.message}
        </div>

        <div className="field">
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Role</InputLabel>
            <Controller
              control={control}
              name="role"
              render={({
                field: { onChange, onBlur, value, name, ref },
                fieldState: { invalid, isTouched, isDirty, error },
                formState,
              }) => (
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  label="Age"
                  defaultValue={""}
                  onChange={onChange}
                  value={value}
                >
                  <MenuItem value={"admin"}>Admin</MenuItem>
                  <MenuItem value={"teacher"}>Teacher</MenuItem>
                  <MenuItem value={"student"}>Student</MenuItem>
                  <MenuItem value={"blueMountain"}>Blue Mountain</MenuItem>
                </Select>
              )}
              rules={{ required: "This field is required" }}
            />
          </FormControl>
          {errors?.role?.message}
        </div>

        <button>submit</button>
      </form>
    </div>
  );
};

export default styled(SignUp)`
  width: 500px;
  margin: auto;
  margin-top: 10px;
  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 400px;
  }

  .field {
    display: flex;
    flex-direction: column;
  }
`;
