import React from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import Button from "./common-component/next-back-button";
import { useNavigate } from "react-router-dom";
import CheckmarkAnimation from "./Reusable/checkmark-animation";

export default function Testing({ className, selectedSchool }) {
  const navigate = useNavigate();

  const language = useSelector((state) => state.language);
  const screen = useSelector((state) => state.screen);

  if (screen === "mobile") {
    return (
      <Mobile>
        <div>
          <CheckmarkAnimation />
          <h1>Submitted</h1>
        </div>

        <div>
          <h4>You have successfully registered!</h4>
          <p>
            Your application has been submitted to the KCSA. You will receive a
            confirmation email in 5-10 minutes.
          </p>
        </div>

        <Button name="Back to home" onClick={() => navigate("/")} />
      </Mobile>
    );
  } else {
    return (
      <Desktop>
        <p>Thank you! Your Application has been submitted for:</p>

        <div className="school-details">
          <p className="name">School Name</p>
          <p className="location">School address</p>
        </div>

        <p>Please check your email for confirmation.</p>
      </Desktop>
    );
  }
}

const Desktop = styled.div`
  width: 925px;
  margin: 20px auto;

  background: rgba(57, 179, 0, 0.1);
  border: 1px solid #39b300;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 6px;

  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Mobile = styled.div`
  max-width: 385px;

  padding-top: 70px;
  width: 80%;
  margin: auto;

  input {
    font-size: 17px;
    padding-left: 10px;
    border-radius: 10px;
    border: 1px solid grey;
  }
  p:first-child {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;
    /* identical to box height, or 220% */

    color: #191919;

    opacity: 0.8;
  }

  h1 {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    color: #000a23;
    box-sizing: border-box;
    height: 36px;
    margin: 0;
    margin-bottom: 25px;
    margin-top: 10px;
  }

  > div:nth-of-type(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 25px;
  }

  > div:nth-of-type(2) {
    h4 {
      font-size: 18px;
    }

    p {
      margin-bottom: 25px;
    }
  }
`;
