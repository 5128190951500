import React, { useState } from "react";
import styled from "styled-components";
import { useNavigate, useParams } from "react-router-dom";

import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import PersonIcon from "@mui/icons-material/Person";
import BlueMobileButton from "../../Reusable/blue-mobile-button";

const InputComp = ({
  className,
  fullName,
  setFullName,
  email,
  setEmail,
  phoneNumber,
  setPhoneNumber,
}) => {
  const navigate = useNavigate();

  const { applicantType } = useParams();

  const [fullNameError, setFullNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [phoneNumberError, setPhoneNumberError] = useState(false);

  const [fullNameTouched, setFullNameTouched] = useState(false);
  const [emailTouched, setEmailTouched] = useState(false);
  const [phoneNumberTouched, setPhoneNumberTouched] = useState(false);

  const handleFullNameChange = (e) => {
    const value = e.target.value;
    if (/^[a-zA-Z\s]*$/.test(value)) {
      setFullName(value);
    }

    if (fullNameTouched) {
      if (!/\S+\s+\S+/.test(value)) {
        setFullNameError(true);
      } else {
        setFullNameError(false);
      }
    }
  };

  const handleFullNameBlur = () => {
    setFullNameTouched(true);
    if (!/\S+\s+\S+/.test(fullName)) {
      setFullNameError(true);
    } else {
      setFullNameError(false);
    }
  };

  const handleEmailChange = (e) => {
    const value = e.target.value;
    if (/^[-_@.a-zA-Z0-9]*$/.test(value)) {
      setEmail(value);
    }

    if (emailTouched) {
      if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(value)) {
        setEmailError(true);
      } else {
        setEmailError(false);
      }
    }
  };

  const handleEmailBlur = () => {
    setEmailTouched(true);
    if (!/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(email)) {
      setEmailError(true);
    } else {
      setEmailError(false);
    }
  };

  const handlePhoneNumberChange = (event) => {
    const rawPhoneNumber = event.target.value;
    const digits = rawPhoneNumber.replace(/\D+/g, "");

    let newFormattedPhoneNumber;

    if (
      rawPhoneNumber.endsWith("-") &&
      rawPhoneNumber.length === phoneNumber.length - 1
    ) {
      newFormattedPhoneNumber = rawPhoneNumber.slice(0, -1);
    } else if (digits.length >= 6) {
      newFormattedPhoneNumber = `(${digits.slice(0, 3)}) ${digits.slice(
        3,
        6
      )} ${digits.slice(6)}`;
    } else if (digits.length >= 3) {
      newFormattedPhoneNumber = `(${digits.slice(0, 3)}) ${digits.slice(3)}`;
    } else {
      newFormattedPhoneNumber = digits;
    }

    if (newFormattedPhoneNumber.length <= 14) {
      setPhoneNumber(newFormattedPhoneNumber);
      if (phoneNumberTouched) {
        if (newFormattedPhoneNumber.replace(/\D+/g, "").length !== 10) {
          setPhoneNumberError(true);
        } else {
          setPhoneNumberError(false);
        }
      }
    }
  };

  const handlePhoneNumberBlur = () => {
    setPhoneNumberTouched(true);
    if (phoneNumber.replace(/\D+/g, "").length !== 10) {
      setPhoneNumberError(true);
    } else {
      setPhoneNumberError(false);
    }
  };

  return (
    <div
      className={`${className} bg-white p-[50px] drop-shadow-lg rounded-md mt-[50px] mx-8`}
    >
      <div>
        <p>Step 1 of 2</p>

        {applicantType === "student" ? (
          <h2>Student Volunteer Application</h2>
        ) : (
          <h2>Teacher Volunteer Application</h2>
        )}
      </div>

      <TextField
        id="outlined-full-name"
        label="Full Name"
        variant="outlined"
        value={fullName}
        onChange={handleFullNameChange}
        onBlur={handleFullNameBlur}
        error={fullNameError}
        helperText={fullNameError ? "Please enter a first and last name" : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}
        placeholder="Full Name"
      />

      <TextField
        id="outlined-email"
        label="Email"
        variant="outlined"
        value={email}
        onChange={handleEmailChange}
        onBlur={handleEmailBlur}
        error={emailError}
        helperText={emailError ? "Invalid email format" : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <MailOutlineIcon />
            </InputAdornment>
          ),
        }}
        placeholder="example@mail.com"
      />

      <TextField
        id="outlined-phone-number"
        label="Phone Number"
        variant="outlined"
        type="tel"
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        onBlur={handlePhoneNumberBlur}
        error={phoneNumberError}
        helperText={phoneNumberError ? "Phone number must have 10 digits" : ""}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PhoneAndroidIcon />
            </InputAdornment>
          ),
        }}
        placeholder="(xxx) xxx xxxx"
      />

      <BlueMobileButton
        name="Next"
        disabled={
          !fullName ||
          !email ||
          !phoneNumber ||
          fullNameError ||
          emailError ||
          phoneNumberError
        }
        onClick={() => navigate(`/volunteer/${applicantType}/explain-reason`)}
      />
    </div>
  );
};

export default styled(InputComp)`
  display: flex;
  flex-direction: column;
  gap: 35px;

  h2 {
    margin-bottom: 10px;
    margin-top: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    /* or 36px */

    color: #000a23;
  }

  p {
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;
    /* identical to box height, or 220% */

    color: #191919;

    opacity: 0.8;
  }
`;
