import React from "react";
import styled from "styled-components";

const CampDetails = ({ className }) => {
  return (
    <div className={className}>
      <h2 className="font-bold">KCSA Summer Camp Details</h2>
      <p>
        <strong>Who:</strong> Children in Kindergarten (born in 2020) through
        Grade 8
      </p>
      <p>
        <strong>When:</strong> July 2nd - July 26th (Mon - Fri)
      </p>
      <p>
        <strong>Where:</strong> Cummer Valley Middle School, North York, Ontario
      </p>

      <h2 className="font-bold">Program Details</h2>
      <p className="font-bold mt-10">Morning Session: Korean Language Class</p>
      <li>
        <strong>Time:</strong> 9:00 AM - 11:30 AM
      </li>
      <li>
        <strong>Experience:</strong> Engage in interactive Korean language
        lessons designed for young learners. A great opportunity to develop new
        language skills!
      </li>
      <li>
        <strong>Cost:</strong> $20 for a four-week session
      </li>
      <li>
        <strong>Registration:</strong> Separate through the TDSB.
      </li>

      <p className="font-bold mt-10">Lunch Break</p>
      <p>Lunch is included for afternoon session students.</p>
      <li>
        <strong>Monday:</strong> Bring your own lunch
      </li>
      <li>
        <strong>Tuesday to Thursday:</strong> Korean cuisine
      </li>
      <li>
        <strong>Friday:</strong> Pizza day
      </li>

      <p className="font-bold mt-10">
        Afternoon Session: Korean Cultural Exploration
      </p>
      <li>
        <strong>Time:</strong> 1:00 PM - 4:00 PM
      </li>
      <li>
        <strong>Experience:</strong> Dive into Korean culture with activities in
        music, dance, art, history, and outdoor sports.
      </li>
      <li>
        <strong>Cost:</strong> $170 per week
      </li>

      <p className="font-bold mt-10">Late Care Program</p>
      <li>
        <strong>Time:</strong> 4:00 PM - 5:00 PM
      </li>
      <li>
        <strong>Details:</strong> Available for those who need it
      </li>
      <li>
        <strong>Cost:</strong> $40 per week
      </li>

      <p className="font-bold mt-10">Payment Information</p>
      <li>
        For morning sessions, payment must be made directly to TDSB
        (instructions will follow).
      </li>
      <li>
        For afternoon sessions & late care sessions, payments can be
        e-transferred to info@koreanschools.org.
      </li>

      <h2 className="font-bold">Why KCSA Summer School?</h2>
      <li>
        Engaging & Educational: Our camp offers a rich blend of learning and fun
        through cultural immersion.
      </li>
      <li>
        Flexible Care: Late care available to accommodate varying family
        schedules.
      </li>
      <li>
        Safe & Encouraging Environment: Held in a reputable school, ensuring a
        safe and supportive setting for all activities.
      </li>

      <h2 className="font-bold">Register Now!</h2>
      <p>
        Unlock a summer of learning and fun for your child by enrolling them in
        our Korean Summer Camp.{" "}
      </p>
    </div>
  );
};

export default styled(CampDetails)`
  padding: 0px 10vw;

  h2 {
    font-size: 7vw;
    margin-top: 50px;
    margin-bottom: 14px;
  }

  p,
  li {
    font-size: 16px;
  }

  p {
    margin-bottom: 10px;
  }

  p:nth-of-type(3) {
    margin-bottom: 0px;
  }

  li:nth-of-type(3) {
    margin-bottom: 15px;
  }

  .pricing {
    margin-top: 10vw;

    p:nth-of-type(1) {
      margin-bottom: 20px;
    }

    p {
      margin: 0;
    }

    li {
      margin-bottom: 20px;
    }
  }

  @media only screen and (min-width: 500px) {
    h2 {
      font-size: 25px;
    }

    p,
    li {
      font-size: 16px;
    }
  }

  @media only screen and (min-width: 768px) {
    h2 {
      font-size: 28px;
    }
    padding: 0px 16vw;
    p,
    li {
      font-size: 20px;
    }
  }

  @media only screen and (min-width: 1028px) {
    p,
    li {
      font-size: 20px;
    }

    h2 {
      font-size: 35px;
    }

    .pricing {
      margin-top: 75px;
    }
  }
`;
