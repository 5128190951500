import React from "react";
import styled from "styled-components";

const CheckmarkAnimation = ({ className }) => {
  return (
    <div className={className}>
      <div class="checkmark-container">
        <svg viewBox="0 0 100 100">
          <circle
            class="outer-circle-path"
            cx="50"
            cy="50"
            r="48"
            fill="none"
            stroke-width="4"
          />
          <circle class="inner-circle" cx="50" cy="50" r="42" fill="#28a745" />
          <path
            class="checkmark-path"
            d="M25 50 L45 70 L75 40"
            fill="none"
            stroke-width="8"
            stroke-linecap="round"
          />
        </svg>
      </div>
    </div>
  );
};

export default styled(CheckmarkAnimation)`
  .checkmark-container {
    width: 100px;
    height: 100px;
    position: relative;
  }

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }

  .outer-circle-path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    stroke: #28a745;
  }

  .checkmark-path {
    stroke-dasharray: 1000;
    stroke-dashoffset: 1000;
    stroke: white;
  }

  @keyframes outer-circle-draw {
    to {
      stroke-dashoffset: 0;
    }
  }

  @keyframes checkmark-draw {
    from {
      stroke-dashoffset: 1000;
    }
    to {
      stroke-dashoffset: 0;
    }
  }

  .outer-circle-path {
    animation: outer-circle-draw 1s forwards;
  }

  .checkmark-path {
    animation: checkmark-draw 2s 0.4s forwards;
  }
`;
