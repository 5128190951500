import React, { useState } from "react";
import styled from "styled-components";
import rightPointingArrow from "../../images/rightPointingArrow.png";
import Select from "react-select";
import { Controller } from "react-hook-form";

// Redux
import Button from "../common-component/next-back-button";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import PersonIcon from "@mui/icons-material/Person";
import NumericInputIcon from "@mui/icons-material/Filter9Plus";

import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MuiSelect from "@mui/material/Select";
import { FormHelperText } from "@mui/material";

export default function StudentInformation({
  className,
  setPage,
  register,
  trigger,
  errors,
  control,
  watch,
  setValue,
  selectedSchool,
}) {
  const [fullNameTouched, setFullNameTouched] = useState(false);
  const [inputTouched, setInputTouched] = useState(false);

  const data = watch();

  const onClick = (e) => {
    e.preventDefault();
    trigger().then((output) => {
      if (output !== false) setPage("address");
    });
  };

  const grades = [
    { value: "Junior Kindergarten", label: "Junior Kindergarten" },
    { value: "Senior Kindergarten", label: "Senior Kindergarten" },
    { value: "1", label: "Grade 1" },
    { value: "2", label: "Grade 2" },
    { value: "3", label: "Grade 3" },
    { value: "4", label: "Grade 4" },
    { value: "5", label: "Grade 5" },
    { value: "6", label: "Grade 6" },
    { value: "7", label: "Grade 7" },
    { value: "8", label: "Grade 8" },
  ];

  const genders = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
    { value: "Other", label: "Other" },
  ];

  const validateFullName = (value) => {
    if (!/^[a-zA-Z\s]*$/.test(value)) {
      return "Only letters and spaces are allowed";
    }
    if (!/\S+\s+\S+/.test(value)) {
      return "Please enter a first and last name";
    }
    return true;
  };

  const handleFullNameBlur = () => {
    setFullNameTouched(true);
  };

  const validateInput = (value) => {
    const strippedValue = value.replace(/-/g, "");
    if (!/^\d{9}$/.test(strippedValue)) {
      return "Invalid format. Please enter your 9 digit OEN number.";
    }
    return true;
  };

  const handleInputChange = (event) => {
    const rawValue = event.target.value;

    const digits = rawValue.replace(/\D+/g, "");

    let newOenValue;

    if (rawValue.endsWith("-") && rawValue.length === rawValue.length - 1) {
      newOenValue = rawValue.slice(0, -1);
    } else if (digits.length >= 7) {
      newOenValue = `${digits.slice(0, 3)}-${digits.slice(3, 6)}-${digits.slice(
        6,
        9
      )}`;
    } else if (digits.length >= 4) {
      newOenValue = `${digits.slice(0, 3)}-${digits.slice(3)}`;
    } else {
      newOenValue = digits;
    }

    if (newOenValue.length <= 11) {
      setValue("oen", newOenValue);
    }

    // Trigger validation after input value changes
    inputTouched && trigger("oen");
  };

  const handleInputBlur = () => {
    setInputTouched(true);
  };

  return (
    <div>
      <div className="pt-0">
        <h4 className="text-3xl lg:text-4xl font-bold leading-[1.2] text-center relative mt-4">
          School Registration
        </h4>
        <p className="text-sm sm:text-base text-center mt-4 whitespace-pre-wrap">
          {`Great choice! You've selected\n${selectedSchool.name}.`}
        </p>
      </div>
      <StudentInfo className="lg:bg-white p-8 rounded-lg mt-2 mx-auto drop-shadow-lg mt-8">
        <div>
          <p className="text-xs">School Registration - Step 2 of 4</p>
          <h1 className="section">Student Info</h1>
        </div>

        <TextField
          id="outlined-full-name"
          label="Student Full Name"
          variant="outlined"
          name="fullName"
          onBlur={handleFullNameBlur}
          error={Boolean(errors.fullName) && fullNameTouched}
          helperText={
            errors.fullName && fullNameTouched && errors.fullName.message
          }
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <PersonIcon />
              </InputAdornment>
            ),
            inputProps: {
              ...register("fullName", {
                required: "This field is required",
                validate: validateFullName,
              }),
            },
          }}
          placeholder="ex. Andy Kim"
        />

        <TextField
          name="oen"
          id="outlined-formatted-input"
          label="Ontario Education Number (OEN)"
          variant="outlined"
          value={data.oen}
          onBlur={handleInputBlur}
          error={Boolean(errors.oen) && inputTouched}
          helperText={errors.oen && inputTouched && errors.oen.message}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <NumericInputIcon />
              </InputAdornment>
            ),
            inputProps: {
              ...register("oen", {
                required: "This field is required",
                validate: validateInput,
              }),
              onChange: handleInputChange,
            },
          }}
          placeholder="### - ### - ###"
        />

        <div>
          <FormControl
            variant="outlined"
            fullWidth
            error={Boolean(errors.grade)}
          >
            <InputLabel htmlFor="grade">Grade</InputLabel>
            <Controller
              control={control}
              name="grade"
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <MuiSelect
                  label="Grade"
                  {...field}
                  onChange={(event) => field.onChange(event.target.value)}
                >
                  {grades.map((grade) => (
                    <MenuItem key={grade.value} value={grade.value}>
                      {grade.label}
                    </MenuItem>
                  ))}
                </MuiSelect>
              )}
            />
            {errors.grade && (
              <FormHelperText error>{errors.grade.message}</FormHelperText>
            )}
          </FormControl>
        </div>

        <div>
          <FormControl
            variant="outlined"
            fullWidth
            error={Boolean(errors.gender)}
          >
            <InputLabel htmlFor="gender">Gender</InputLabel>
            <Controller
              control={control}
              name="gender"
              defaultValue=""
              rules={{ required: "This field is required" }}
              render={({ field }) => (
                <MuiSelect
                  label="Gender"
                  {...field}
                  onChange={(event) => field.onChange(event.target.value)}
                >
                  {genders.map((gender) => (
                    <MenuItem key={gender.value} value={gender.value}>
                      {gender.label}
                    </MenuItem>
                  ))}
                </MuiSelect>
              )}
            />
            {errors.gender && (
              <FormHelperText error>{errors.gender.message}</FormHelperText>
            )}
          </FormControl>
        </div>

        <div className="mt-5">
          <Button
            name="Next"
            disabled={
              !data?.fullName ||
              !data?.oen ||
              !data?.grade ||
              !data?.gender ||
              data?.fullName === "" ||
              data?.oen === "" ||
              data?.grade === "" ||
              data?.gender === "" ||
              errors?.fullName ||
              errors?.oen ||
              errors?.grade ||
              errors?.gender
            }
            onClick={onClick}
          />
          <p
            className="text-sm text-center mt-4 cursor-pointer hover:underline text-grey"
            onClick={() => setPage("selectSchool")}
          >
            Back
          </p>
        </div>
      </StudentInfo>
    </div>
  );
}

const StudentInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 35px;
  max-width: 600px;
  /* margin: auto; */
  width: 100%;
  margin-bottom: 50px;
  background: white;

  @media screen and (min-width: 700px) {
  }

  .Mui-error {
    margin: 0;
  }

  > div {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #191919;

    label {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #191919;
      opacity: 0.8;
    }

    > input {
      background: #f4f4f4;
      border-radius: 5px;
      border: none;
      box-sizing: border-box;
      height: 40px;
      width: 100%;
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 22px;
      color: black;
      opacity: 0.8;

      ::placeholder {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: #707070;
        opacity: 0.8;
      }
    }
  }

  @media only screen and (min-width: 700px) {
    padding: 50px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  }
`;
