import React from "react";
import styled from "styled-components";

const UserStatusAlert = ({ userStatus, className }) => {
  let message;
  let adminApproval;

  switch (userStatus) {
    case "pending email approval":
      message = "pending email approval";
      break;
    case "pending admin approval and user info":
      message =
        "click here to update your user profile and access your role privileges";
      adminApproval = "Pending admin approval";
      break;
    case "pending user info":
      message =
        "click here to update your user profile and access your role privileges";
      break;
    case "pending admin approval":
      adminApproval = "pending admin approval";
    default:
      return <></>;
  }

  return (
    <div className={className}>
      {message && <p>{message}</p>}
      {adminApproval && <p>{adminApproval}</p>}
    </div>
  );
};

export default styled(UserStatusAlert)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  transition: all 250ms ease-in-out;

  p {
    border-radius: 5px;
    background-color: #beeebe;
    padding: 10px;
    width: 80%;
    font-size: 14px;
    margin: 5px 0px 0px 0px;

    &:hover {
      cursor: pointer;
      opacity: 0.8;
    }
  }
`;
