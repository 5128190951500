import React, { useRef, useContext } from "react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import styled from "styled-components";
import { Language } from "../../App";

const Schools = ({ className, setCoordinates }) => {
  const { language, setLanguage } = useContext(Language);

  const [address, setAddress] = React.useState("");
  const [show, setShow] = React.useState(true);

  const onFocus = () => setShow(!show);
  const onBlur = () => setShow(!show);

  const handleSelect = async (value) => {
    setShow(!show);
    const results = await geocodeByAddress(value);
    const latLng = await getLatLng(results[0]);
    setAddress(value);
    setCoordinates(latLng);
  };

  return (
    <div className={`${className} max-w-[1400px] mx-auto`}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className={`feather feather-search ${show ? "show" : "hide"}`}
      >
        <circle cx="11" cy="11" r="8"></circle>
        <line x1="21" y1="21" x2="16.65" y2="16.65"></line>
      </svg>

      <PlacesAutocomplete
        value={address}
        onChange={setAddress}
        onSelect={handleSelect}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div>
            <input
              {...getInputProps({
                placeholder: `${
                  language === "english"
                    ? "Search by city, postal code, or address"
                    : "차저 시티 학교 이름"
                }`,
              })}
              onFocus={onFocus}
              onBlur={onBlur}
              className={`${show ? "addPadding" : "removePadding"} ${
                suggestions.length > 0 ? "change-border-radius" : ""
              } gps-input`}
            />

            {suggestions.length > 0 && (
              <div className="suggestions">
                {loading ? <div>...loading</div> : null}

                {suggestions.map((suggestion) => {
                  const style = {
                    backgroundColor: suggestion.active ? "#e8f3ff" : "#fff",
                  };

                  return (
                    <div {...getSuggestionItemProps(suggestion, { style })}>
                      {suggestion.description}
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        )}
      </PlacesAutocomplete>
    </div>
  );
};

export default styled(Schools)`
  position: relative;
  margin-top: 25px;

  .gps-input {
    width: 100%;
    padding: 10px;
    padding-left: 35px;
    font-size: 15px;
    font-family: "Inter";
    font-weight: 200;
    border-radius: 50px;
    border: 1px solid #7e7e7e;
    border-radius: 5px;
    box-sizing: border-box;

    ::placeholder {
      color: #363636;
    }
  }

  .change-border-radius {
    border-radius: 5px 5px 0px 0px;
    outline: none;
  }

  .feather-search {
    position: absolute;
    height: 18px;
    top: 12px;
    left: 10px;
    opacity: 0.6;
  }

  .suggestions {
    border-right: 1px solid grey;
    border-left: 1px solid grey;
    border-bottom: 1px solid grey;
    cursor: pointer;

    > div {
      line-height: 30px;
      padding: 0px 10px;
    }
  }

  @media screen and (max-width: 1200px) {
    width: 100%;

    .gps-input {
      width: 100%;
      font-size: 15px;
      box-sizing: border-box;

      ::placeholder {
        color: #363636;
        font-weight: 400;
        font-size: 13px;
      }
    }

    .suggestions {
      width: 100%;
      box-sizing: border-box;
    }
  }
`;
