import React from "react";
import { useNavigate } from "react-router-dom";

export default function SignOut() {
  const navigate = useNavigate();
  return (
    <div>
      <p>You've successfully signed out.</p>
      <p onClick={() => navigate("/")}>
        Click here to go back to the home page
      </p>
    </div>
  );
}
