import React from "react";
import { useParams } from "react-router-dom";
import girlOnLaptop from "../images/student/girl-on-laptop.png";
import boyWearingBag from "../images/student/boy-wearing-bag.png";
import girlWearingMask from "../images/student/girl-wearing-mask.png";
import teachingWithMask from "../images/teacher/teaching-with-masks.png";
import raisingHand from "../images/teacher/raising-hand.png";
import raisingHandMic from "../images/teacher/raising-hand-mic.png";

const ImagesSection = () => {
  const { applicantType } = useParams();

  return (
    <div className="h-screen max-h-[800px] flex gap-2.5 max-w-[1400px] mx-auto overflow-hidden my-10">
      <img
        src={applicantType === "student" ? girlOnLaptop : teachingWithMask}
        alt=""
        className="object-cover w-1/2 h-full rounded-md"
      />

      <div className="w-1/2 flex flex-col gap-2.5">
        <img
          src={applicantType === "student" ? girlWearingMask : raisingHand}
          alt=""
          className="object-cover w-full h-1/2 rounded-md"
        />
        <img
          src={applicantType === "student" ? boyWearingBag : raisingHandMic}
          alt=""
          className="object-cover w-full h-1/2 rounded-md"
        />
      </div>
    </div>
  );
};

export default ImagesSection;
