const createSlice = require("@reduxjs/toolkit").createSlice;

const initialState = {};

const studentRegistration = createSlice({
  name: "registration",
  initialState,
  reducers: {
    setSchool: (state, { payload }) => {
      state.school = {
        name: payload.name,
        address: payload.address,
        city: payload.city,
        province: payload.province,
        postalCode: payload.postalCode,
      };
    },
    setName: (state, { payload }) => {
      state.name = payload.name;
      state.koreanName = payload.koreanName;
    },
  },
});

export const { setSchool, setName } = studentRegistration.actions;
export default studentRegistration.reducer;
