import React, { useEffect, useState, createContext, useRef } from "react";
import { useMediaQuery } from "react-responsive";
import { Routes, Route, IndexRoute, useLocation } from "react-router-dom";
import { supabase } from "./server";
import "./index.scss";
import logo from "./images/logo-with-name.png";
import styled from "styled-components";
import { ThemeProvider, createTheme } from "@mui/material/styles";

// Redux
import { signIn } from "./slices/userSlice";
import { setScreen } from "./slices/screenSlice";

// Components
import HomePage from "./components/home-page";
import NavBar from "./components/NavBar";
import AboutUs from "./components/AboutUs/index.js";
import Announcements from "./components/Announcements";
import Entry from "./components/Announcements/Entry";
import StudentRegistration from "./components/StudentRegistration";
import Volunteer from "./components/Volunteer";
import VolunteerInfo from "./components/Volunteer/learn-more";

import SignUp from "./components/Authentication/SignUp.js";
import SignIn from "./components/Authentication/SignIn.js";
import AccountVerification from "./components/Authentication/AccountVerification.js";
import Teachers from "./components/Teachers/Teachers.js";
import SignOut from "./components/Authentication/SignOut.js";
// import Registration from "./components/Authentication/Registration";
import UserStatusAlert from "./components/Authentication/UserStatusAlert";
import { useSelector, useDispatch } from "react-redux";

// Footer component
import Testing from "./components/testing";
import SummerCamp from "./components/summer-camp";
import CollectPersonInfo from "./components/Volunteer/application/collect-person-info";
import Application from "./components/Volunteer/application";
import Footer from "./components/footer";
import NewSchool from "./components/Admin/new-school";
import Schools from "./components/Admin/schools";
import EditSchool from "./components/Admin/edit-school";

export const Language = createContext("english");
export const SchoolsContext = createContext([]);

const theme = createTheme();

export default function App() {
  const dispatch = useDispatch();
  const location = useLocation();

  const [language, setLanguage] = useState("english");
  const [schools, setSchools] = useState([]);
  const [userStatus, setUserStatus] = useState("loading");
  const user = useSelector((state) => state.auth.user);

  const isMobile = useMediaQuery({ query: "(max-width: 768px)" });

  const authenticated =
    JSON.parse(localStorage.getItem("sb-lqzahgakgtrswulxeedj-auth-token"))?.user
      ?.aud === "authenticated";

  useEffect(() => {
    dispatch(setScreen(isMobile ? "mobile" : "desktop"));
  }, [isMobile, dispatch]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [location]);

  useEffect(() => {
    async function querySchool() {
      const { data, error } = await supabase.from("school").select(`
        *
      `);

      if (error) return error;
      else return data;
    }

    querySchool().then((res) => {
      setSchools(res);
    });
  }, []);

  useEffect(() => {
    (async () => {
      // if (error || data?.session === null) {
      //   console.log(error);
      //   return setUserStatus(false);
      // }
      // const users_id = data?.session?.user?.id;
      // const authenticated = data?.session.user.role;
      // if (!authenticated) {
      //   setUserStatus("pending email approval");
      // } else {
      //   const { data, error } = await supabase
      //     .from("user")
      //     .select(`*`)
      //     .eq("users_id", users_id);
      //   if (!data?.[0].approval && !data?.[0]?.firstName) {
      //     return setUserStatus("pending admin approval and user info");
      //   } else if (!data[0].approval) {
      //     return setUserStatus("pending admin approval");
      //   } else {
      //     return setUserStatus("pending user info");
      //   }
      // }
    })();
  }, []);

  // if (isMobile) {
  //   return (
  //     <MobileAlert>
  //       <img src={logo} alt="" />

  //       <p>The mobile page is coming soon!</p>
  //       <p>Please view this app on a desktop for now.</p>
  //       <p>Thank you for your patience!</p>
  //     </MobileAlert>
  //   );
  // }

  return (
    <>
      <ThemeProvider theme={theme}>
        <Language.Provider value={{ language, setLanguage }}>
          <SchoolsContext.Provider value={{ schools }}>
            <div className="korean-gradient"></div>
            <NavBar />

            {user && <UserStatusAlert userStatus={userStatus} />}

            <Routes>
              <Route exact path="/about-us" element={<AboutUs />} />
              <Route exact path="/announcements" element={<Announcements />} />
              <Route
                exact
                path="/announcements/:announcementId"
                element={<Entry />}
              />
              <Route exact path="/full-article" element={<Entry />} />

              <Route
                exact
                path="/student-registration"
                element={<StudentRegistration />}
              >
                <Route exact path="/student-registration/student-information" />
              </Route>

              <Route exact path="/volunteer" element={<Volunteer />} />
              <Route
                exact
                path="/volunteer/:applicantType"
                element={<VolunteerInfo />}
              />
              <Route
                exact
                path="/volunteer/:applicantType/:applicationStep"
                element={<Application />}
              />

              <Route exact path="/sign-up" element={<SignUp />} />
              <Route exact path="/sign-in" element={<SignIn />} />
              <Route
                exact
                path="/sign-in?confirmation=true"
                element={<AboutUs />}
              />

              <Route
                exact
                path="/account-verification"
                element={<AccountVerification />}
              />
              <Route exact path="/sign-out" element={<SignOut />} />

              <Route exact path="/summer-camp" element={<SummerCamp />} />

              {user?.email && (
                <Route path="authenticated" element={<HomePage />}>
                  <Route path="teachers" element={<Teachers />} />
                </Route>
              )}

              <Route path="testing" element={<Testing />} />

              {authenticated && <Route path="admin" element={<Schools />} />}
              <Route path="admin/school/:schoolId" element={<EditSchool />} />
              <Route path="admin/new-school" element={<NewSchool />} />

              <Route path="*" element={<HomePage />} />
            </Routes>
            <Footer />
          </SchoolsContext.Provider>
        </Language.Provider>
      </ThemeProvider>
    </>
  );
}
