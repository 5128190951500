const createSlice = require("@reduxjs/toolkit").createSlice;

const initialState = "desktop";

const screenSlice = createSlice({
  name: "screen",
  initialState,
  reducers: {
    setScreen: (state, { payload }) => {
      return (state = payload);
    },
  },
});

export const { setScreen } = screenSlice.actions;
export default screenSlice.reducer;
