import React, { useState } from "react";
import Dropzone from "react-dropzone-uploader";
import ReactQuill from "react-quill";
import styled from "styled-components";
import { supabase } from "../../server";
import CircularWithValueLabel from "../common-component/loading-animation";

function getValueAfterSlash(inputString) {
  const parts = inputString.split("/");
  return parts[1];
}

async function insertPublicAnnouncement(title, entry, files) {
  const { data, error } = await supabase
    .from("announcement")
    .insert({
      title,
      entry,
      images: files.map(
        (file, index) => `${index}.${getValueAfterSlash(file.file.type)}`
      ),
    })
    .select();

  return data[0];
}

const NewPost = ({ className, title, queryAnnouncement, setAnnouncements }) => {
  const [value, setValue] = useState("");
  const [loadingAnimation, setLoadingAnimation] = useState(false);

  console.log(loadingAnimation);

  const handleSubmit = async (files, allFiles) => {
    try {
      setLoadingAnimation(true);

      allFiles.forEach((f) => f.remove());

      const announcementData = await insertPublicAnnouncement(
        title.current.value,
        value,
        files
      );

      setValue("");
      // title.current.value = "";

      // Get the id of the announcement record from the database
      const id = announcementData.id;

      // Upload each file to Google Cloud Storage
      let index = 0;
      for (const fileWrapper of files) {
        const file = fileWrapper.file;

        // Request a signed URL from your server-side endpoint
        const apiUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:3001/google-cloud/get-signed-url"
            : "https://korean-school.herokuapp.com/google-cloud/get-signed-url";

        const response = await fetch(apiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fileName: `${id}-${index}.${getValueAfterSlash(file.type)}`,
            contentType: file.type,
            type: "announcement",
          }),
        });

        const responseData = await response.json();

        // Upload the file to Google Cloud Storage using the signed URL
        const signedUrl = responseData.url;

        console.log(signedUrl);

        await fetch(signedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
        });

        index++;
      }

      const announcementsData = await queryAnnouncement();
      setAnnouncements(announcementsData);
    } catch (err) {
      console.error(err);
    } finally {
      setLoadingAnimation(false);
    }
  };

  return loadingAnimation ? (
    <div className="flex justify-center mt-10 mb-10">
      <CircularWithValueLabel />
    </div>
  ) : (
    <div className={className}>
      <div className="editor w-full max-w-4xl mx-auto mb-20 flex flex-col gap-2.5 relative bg-white p-6 rounded-md drop-shadow-lg">
        <input
          placeholder="Title of Article"
          ref={title}
          className="h-10 px-2.5 text-lg border border-[#ccc] font-bold"
        />

        <ReactQuill
          theme="snow"
          value={value}
          onChange={setValue}
          placeholder="Write something here"
        />

        <Dropzone onSubmit={handleSubmit} accept="image/*, video/*" />
      </div>
    </div>
  );
};

export default styled(NewPost)`
  .quill {
    p {
      /* font-size: 12px; */
    }
  }
  .dzu-inputLabelWithFiles {
    display: none;
  }

  .dzu-dropzone {
    border: none;
    min-height: 40px;
  }

  .dzu-inputLabel {
    border: 1px solid #ccc;
    border-radius: 8px;
    font-size: 14px;
  }
`;
