import React from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import registerImage from "./images/register.jpeg";

import Button from "../common-component/button";

const Register = ({ className }) => {
  const navigate = useNavigate();
  return (
    <div className={`${className} w-[100vw] text-center mt-10 lg:mt-[100px] `}>
      <div className=" mx-auto">
        <h2 className="text-3xl font-bold mx-auto lg:text-4xl xl:text-5xl">
          Register Today!
        </h2>

        <p className="mt-5 lg:max-w-[800px] mx-auto">
          Enrollments for Korean language classes just became easier! Take
          advantage of our new online registration portal now available through
          the KCSA website.
        </p>

        <button
          onClick={() => navigate("/student-registration")}
          className="bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500 mt-8"
        >
          Register
        </button>

        <img
          src={registerImage}
          alt="Register to a Korean Education School today"
          className="w-[100vw] rounded-md mx-auto mt-10 lg:mt-[100px] max-h-[90vh] object-cover"
        />

        {/* <Button
          name="Find a school"
          onClick={() => navigate("/student-registration")}
          color="white"
        /> */}
      </div>
    </div>
  );
};

export default styled(Register)``;
