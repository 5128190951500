import React from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";

import BlackBorderButton from "../../Reusable/black-border-button";
import studentImage from "../images/student/main-image.png";
import teacherImage from "../images/teacher/teacher-image.png";

const Main = ({ className, applicantType }) => {
  const navigate = useNavigate();

  return (
    <div className={className}>
      <h2>Volunteer and gain experience</h2>

      <BlackBorderButton
        name="Apply now"
        onClick={() =>
          navigate(`/volunteer/${applicantType}/collect-person-info`)
        }
      />
    </div>
  );
};

const getBackgroundImage = (applicantType) => {
  return applicantType === "student" ? studentImage : teacherImage;
};

const StyledMain = styled(Main)`
  background-image: url(${(props) => getBackgroundImage(props.applicantType)});
  background-repeat: no-repeat;
  background-position: center calc(80%);
  /* background-attachment: fixed; */
  background-size: cover;
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 20vh;
  padding-left: 10px;
  padding-right: 10px;

  box-sizing: border-box;

  h2 {
    text-align: center;
    font-weight: 600;
    font-size: 25px;
    text-align: center;
    color: #000a23;
  }

  @media only screen and (min-width: 1200px) {
    h2 {
      font-size: 2.5em;
    }
  }
`;

const StyledMainWithParams = (props) => {
  const { applicantType } = useParams();
  return <StyledMain {...props} applicantType={applicantType} />;
};

export default StyledMainWithParams;
