import React from "react";
import styled from "styled-components";

const BlackBorderButton = ({ className, name, onClick }) => {
  return (
    <div className={className} onClick={onClick}>
      {name}
    </div>
  );
};

export default styled(BlackBorderButton)`
  padding: 4px 12px;
  width: fit-content;
  border: 2px solid #000000;
  border-radius: 10px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 600;
  font-size: 4vw;
  line-height: 150%;
  text-align: center;
  color: #000000;

  :hover {
    color: white;
    background: black;
  }

  @media screen and (min-width: 450px) {
    font-size: 18px;
  }
`;
