import React from "react";
import Logo from "./logo.png";

const Footer = () => {
  return (
    <footer
      className="w-[100vw] border-t-[1px] border-gray"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:px-16">
        <div className="flex flex-col items-baseline space-y-6">
          <div className="mx-auto">
            <a
              href="/"
              className="mx-auto text-lg text-center text-black transition duration-500 ease-in-out transform tracking-relaxed"
            >
              <img src={Logo} alt="" className="h-14" />
            </a>
          </div>
          <div className="mx-auto">
            <span className="mx-auto mt-2 text-sm text-gray-500 w-full">
              Copyright © 2020 - 2021. Since 2020
            </span>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
