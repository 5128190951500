import React, { useRef, useState } from "react";
import { supabase } from "../../server";
import { useNavigate } from "react-router-dom";

const AddSchool = () => {
  const navigate = useNavigate();

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const dayOfOperation = useRef();
  const hours = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const postalCodeRef = useRef();
  const provinceRef = useRef();
  const regionRef = useRef();
  const latitudeRef = useRef();
  const longitudeRef = useRef();

  const [isLoading, setIsLoading] = useState(false);

  const handleAdd = async () => {
    setIsLoading(true);

    const newSchool = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      hoursOfOperation: {
        [dayOfOperation.current.value]: hours.current.value,
      },
      address: addressRef.current.value,
      city: cityRef.current.value,
      postalCode: postalCodeRef.current.value,
      province: provinceRef.current.value,
      region: regionRef.current.value,
      latitude: latitudeRef.current.value,
      longitude: longitudeRef.current.value,
    };

    const { error } = await supabase.from("school").insert([newSchool]);
    if (error) {
      console.error("Failed to add school:", error);
    } else {
      console.log("School added successfully!");
    }

    navigate("/admin");
    setIsLoading(false);
  };

  return (
    <div className="flex flex-col max-w-[700px] mx-auto">
      <h2 className="text-xl mb-2">Add New School</h2>

      <div className="general-details mb-4 grid grid-cols-2 gap-1">
        <label>Name:</label>
        <input ref={nameRef} />

        <label>Email:</label>
        <input ref={emailRef} />

        <label>Phone:</label>
        <input ref={phoneRef} />

        <label>Hours of Operation:</label>
        <div>
          <input ref={dayOfOperation} />
          <input ref={hours} />
        </div>
      </div>

      <div className="location-details mb-4 grid grid-cols-2 gap-1">
        <label>Address:</label>
        <input ref={addressRef} />

        <label>City:</label>
        <input ref={cityRef} />

        <label>Postal Code:</label>
        <input ref={postalCodeRef} />

        <label>Province:</label>
        <input ref={provinceRef} />

        <label>Region:</label>
        <input ref={regionRef} />

        <label>Latitude:</label>
        <input ref={latitudeRef} />

        <label>Longitude:</label>
        <input ref={longitudeRef} />
      </div>

      <div className="flex justify-end">
        <button
          onClick={handleAdd}
          className="transition duration-200 ease-in-out cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500 w-[150px]"
          disabled={isLoading}
        >
          {isLoading ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default AddSchool;
