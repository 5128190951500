import React, { useState, useEffect } from "react";
import SelectSchool from "./SelectSchool";
import GpsInput from "./gps-input";
import { supabase } from "../../server";
import { useForm } from "react-hook-form";
import StudentInformation from "./StudentInformation";
import Address from "./Address";
import GuardianInformation from "./GuardianInformation";
import Complete from "./Complete";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const StudentRegistrationMain = ({ className }) => {
  const navigate = useNavigate();

  const [schools, setSchools] = useState([]);
  const [page, setPage] = useState("selectSchool");
  const [selectedSchool, setSelectedSchool] = useState();
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    watch,
    trigger,
    formState: { errors },
    control,
    setValue,
  } = useForm({ mode: "all" });

  useEffect(() => {
    async function querySchool() {
      const { data, error } = await supabase.from("school").select(`*`);
      // .neq("name", null)
      // .neq("longitude", null);

      if (error) return error;
      else return data;
    }

    querySchool().then((res) => {
      setSchools(res);
    });
  }, []);

  const onSubmit = async (data) => {
    setLoading(true);

    const apiUrl =
      process.env.NODE_ENV === "development"
        ? "http://localhost:3001/email/student-registration"
        : "https://korean-school.herokuapp.com/email/student-registration";

    const bodyData = data;
    bodyData["school"] = selectedSchool;

    fetch(apiUrl, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(data),
    }).then((res) => {
      res.status === 200 && setPage("complete");
      setLoading(false);
    });

    const { error } = await supabase.from("studentRegistration").insert({
      name: bodyData["fullName"],
      oen: bodyData["oen"],
      grade: bodyData["grade"],
      gender: bodyData["gender"],
      address: bodyData["address"],
      city: bodyData["city"],
      province: bodyData["province"],
      postalCode: bodyData["postalCode"],
      guardianName: bodyData["guardianName"],
      guardianRelationship: bodyData["guardianRelationship"],
      guardianEmail: bodyData["guardianEmail"],
      phoneNumber: bodyData["guardianPhone"],
      school: selectedSchool.name,
    });
  };

  return (
    <div className={`${className} mx-auto mt-4 md:mt-8`}>
      {page === "selectSchool" && (
        <SelectSchool
          setSelectedSchool={setSelectedSchool}
          setPage={setPage}
          schools={schools}
        />
      )}

      <form onSubmit={handleSubmit(onSubmit)}>
        {page === "studentInformation" && (
          <StudentInformation
            setPage={setPage}
            register={register}
            trigger={trigger}
            errors={errors}
            control={control}
            watch={watch}
            setValue={setValue}
            selectedSchool={selectedSchool}
          />
        )}

        {page === "address" && (
          <Address
            setPage={setPage}
            register={register}
            trigger={trigger}
            errors={errors}
            watch={watch}
          />
        )}

        {page === "guardian information" && (
          <GuardianInformation
            setPage={setPage}
            register={register}
            trigger={trigger}
            errors={errors}
            loading={loading}
            watch={watch}
            setValue={setValue}
          />
        )}

        {page === "complete" && <Complete selectedSchool={selectedSchool} />}
      </form>
    </div>
  );
};

export default styled(StudentRegistrationMain)`
  /* padding-top: 25px; */
  max-width: 98%;
  /* margin: auto; */

  .step {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 22px;
    color: #191919;
    opacity: 0.8;
    margin-top: 30px;
  }

  .section {
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    color: #000a23;
    box-sizing: border-box;
    margin: 0;
    margin-bottom: 25px;
  }

  .Mui-error {
    margin: 0;
    color: #191919;
  }
`;
