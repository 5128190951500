import React from "react";
import styled from "styled-components";

export default function Button({ name, disabled, onClick }) {
  return (
    <StyledButton disabled={disabled} onClick={onClick}>
      {name}
    </StyledButton>
  );
}

const StyledButton = styled.button`
  background: ${(props) =>
    props.disabled ? "rgba(0, 49, 110, 0.33)" : "#00316E"};
  border-radius: 5px;
  width: 100%;

  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  /* or 157% */

  text-align: center;

  color: #ffffff;

  height: 40px;
  border: none;
  cursor: pointer;
`;
