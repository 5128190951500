import { createClient } from "@supabase/supabase-js";
import AsyncStorage from "@react-native-async-storage/async-storage";

export const supabase = createClient(
  "https://lqzahgakgtrswulxeedj.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImxxemFoZ2FrZ3Ryc3d1bHhlZWRqIiwicm9sZSI6ImFub24iLCJpYXQiOjE2NzE0MjM0MTUsImV4cCI6MTk4Njk5OTQxNX0.jh8nXg2HILewvvw-fFtH5etYfNkZHr8csTyidKDAq5E",
  {
    auth: {
      storage: AsyncStorage,
      autoRefreshToken: true,
      persistSession: true,
      detectSessionInUrl: false,
    },
  }
);
