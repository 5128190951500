import React from "react";
import styled from "styled-components";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";

const Video = ({ className }) => {
  return (
    <div className={className}>
      <video playsInline autoPlay loop muted>
        <source
          src="https://res.cloudinary.com/dm4r202h4/video/upload/v1713408098/12mb_video_ggyckt.mp4"
          type="video/mp4"
        />
      </video>

      <div>
        <h2>2024 KCSA Summer Camp Registrations are Open!</h2>
        <KeyboardArrowDownIcon />
      </div>
    </div>
  );
};

export default styled(Video)`
  width: 100%;
  position: relative;

  video {
    width: 100%;
    height: 100vh;
    object-fit: cover;
  }

  > div {
    h2 {
      text-align: center;
      margin: 0;
      margin-bottom: 5px;
    }

    position: absolute;
    display: flex;
    flex-direction: column;
    align-items: center;
    bottom: 0px;
    padding-top: 100px;
    padding-bottom: 25px;
    width: 100%;
    background: linear-gradient(to top, #e1e7d5, transparent 100%);
  }
`;
