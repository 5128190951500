import React from "react";
import presidentImage from "../../images/about-us/president.png";

const MessageFromPresident = () => {
  return (
    <div className="py-16 px-7 flex justify-center">
      <div className="max-w-screen-lg text-center">
        <h2 className="text-2xl font-bold mb-10">
          A Message from the President
        </h2>

        <p className="mb-8 leading-relaxed">
          “As the president of KCSA, I am honored to welcome you to our
          organization. Our mission is to promote and preserve the Korean
          language and culture among students in our community, and to inspire
          the next generation to embrace their heritage and expand their
          horizons.
        </p>
        <p className="mb-8 leading-relaxed">
          We believe that by providing a platform for communication and
          collaboration, we can empower our students to not only succeed
          academically but also to become confident, proud, and well-rounded
          individuals.
        </p>
        <p className="mb-10 leading-relaxed">
          I invite you to join us on this journey, whether as a student,
          teacher, or volunteer. Together, we can make a positive impact on the
          lives of students in our community and create a brighter future for
          all. Let's work together to open doors to new opportunities and to
          inspire a love and appreciation for the Korean language and culture.”
        </p>

        <img
          src={presidentImage}
          alt="Okyun Shin - President of KCSA"
          className="w-48 h-48 object-cover mx-auto rounded-full border-2 border-gray-300 shadow-md hover:shadow-lg transition-shadow duration-300 mb-6"
        />

        <h6 className="text-lg font-semibold">Okyun Shin</h6>
        <p className="text-sm text-gray-600">President of KCSA</p>
      </div>
    </div>
  );
};

export default MessageFromPresident;
