import React from "react";
import styled from "styled-components";

const WhiteFooter = ({ className }) => {
  return (
    <div className={className}>
      <div>
        <p>© 2023 Korean Canadian Schools Association</p>
      </div>
    </div>
  );
};

export default styled(WhiteFooter)`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding: 0 52px;
  box-sizing: border-box;

  div {
    border-top: 0.5px solid #858689;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  p {
    padding-top: 13px;
    text-align: center;
    margin-bottom: 50px;
    font-family: "Open Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 11px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #5c5e62;

    opacity: 0.75;
  }
`;
