import React, { useEffect } from "react";
import styled from "styled-components";

// Components
import MainSection from "./main-section";
import ProgramsServices from "./programs-services";
import MessagePresident from "./message-president";
import ThreeKidsImage from "../../images/about-us/three-kids.png";
import { useSelector } from "react-redux";
import MobileAlert from "../../MobileAlert";

import mainImage from "../../images/about-us/main-image.png";

const AboutUs = ({ className }) => {
  const screen = useSelector((state) => state.screen);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={`${className} about-us mx-auto text-center`}>
      <h1 className="mt-16 lg:mt-[50px] text-3xl xl:text-5xl font-bold mx-auto mb-2">
        About KCSA
      </h1>
      <h2 className="my-4 font-bold">
        Our mission to enhance the Korean Education system in Canada
      </h2>
      <p className="text-center mb-10 max-w-[800px] mx-auto lg:text-lg">
        Established in 1986, we are an organization passionate about preserving
        and promoting the Korean language and culture among the youth in our
        community. By connecting students with Korean education programs nearby,
        local Korean schools and students of all backgrounds have a chance to
        learn more about Korea and its history.
      </p>

      <img
        src={mainImage}
        alt=""
        className="rounded-md max-h-[800px] mx-auto w-full object-cover"
      />

      <ProgramsServices />

      <div className="py-[84px] px-8 max-w-[800px] mx-auto">
        <p className="mb-7">
          We are an inclusive organization, open to students of all diverse
          backgrounds who have an interest in learning more about the Korean
          culture. We strive to create an environment where our students can
          feel proud of their heritage and confident in themselves.
        </p>

        <p className="">
          We believe that by coming together, we can achieve great things. Our
          goal is not only to provide our students with the tools they need to
          succeed, but also to inspire them to make a positive impact on their
          communities and the world.
        </p>
      </div>

      <img
        src={ThreeKidsImage}
        alt=""
        className="rounded-md max-h-[800px] mx-auto w-full object-cover"
      />

      <MessagePresident />
    </div>
  );
};

export default styled(AboutUs)``;
