import React, { useState } from "react";
import logo from "./icons/logo.png";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../server";
import { useSelector } from "react-redux";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const screen = useSelector((state) => state?.screen);

  const navigate = useNavigate();

  // Checking if user is logged in using supabase
  const authenticated =
    JSON.parse(localStorage.getItem("sb-lqzahgakgtrswulxeedj-auth-token"))?.user
      ?.aud === "authenticated";

  return (
    <nav className="bg-transparent p-2 md:pt-4">
      <div className=" mx-auto px-4 sm:px-6 lg:px-8">
        <div className="flex items-center justify-between">
          <div className="flex items-center flex-none mr-auto">
            <div
              className="flex-shrink-0 cursor-pointer z-10"
              onClick={() => navigate("/")}
            >
              <img
                className="h-[50px] w-[50px] md:h-[70px] md:w-[70px] object-cover"
                src={logo}
                alt="Logo"
              />
            </div>
          </div>
          <div className="hidden lg:flex justify-center flex-grow absolute left-0 right-0">
            <div className=" flex items-baseline space-x-4 ">
              <a
                href="#"
                className="text-gray-800 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium "
                onClick={() => navigate("/")}
              >
                Home
              </a>
              <a
                href="#"
                className="text-gray-800 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                onClick={() => navigate("/about-us")}
              >
                About Us
              </a>
              <a
                href="#"
                className="text-gray-800 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                onClick={() => navigate("/volunteer")}
              >
                Volunteer
              </a>
              <a
                href="#"
                className="text-gray-800 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                onClick={() => navigate("/announcements")}
              >
                Announcements
              </a>
              {authenticated && (
                <a
                  href="#"
                  className="text-gray-800 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                  onClick={() => navigate("/admin")}
                >
                  Admin
                </a>
              )}
              <a
                href="#"
                className="text-gray-800 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                onClick={() => navigate("/summer-camp")}
              >
                Summer Camp
              </a>
            </div>
          </div>

          {screen !== "mobile" &&
            (!authenticated ? (
              <a
                href="#"
                className="hidden lg:inline-flex text-gray-800 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium cursor-pointer z-10"
                onClick={() => navigate("/sign-in")}
              >
                Sign In
              </a>
            ) : (
              <a
                href="#"
                className="hidden lg:inline-flex text-gray-800 hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium cursor-pointer z-10"
                onClick={async () => {
                  await supabase.auth.signOut();
                  navigate("/");
                }}
              >
                Sign Out
              </a>
            ))}
          <button
            onClick={() => navigate("/student-registration")}
            className="hidden lg:inline-flex transition duration-200 ease-in-out cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500 z-10"
          >
            Register
          </button>

          <div className="-mr-2 flex lg:hidden">
            <button
              onClick={() => setIsOpen(!isOpen)}
              className="text-gray-800 hover:text-gray-600 inline-flex items-center justify-center p-2 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-white focus:ring-gray-800"
            >
              <span className="sr-only">Open main menu</span>
              {isOpen ? (
                <svg
                  className="block h-6 w-6 z-30 fixed right-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              ) : (
                <svg
                  className="block h-6 w-6"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  aria-hidden="true"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M4 6h16M4 12h16m-7 6h7"
                  ></path>
                </svg>
              )}
            </button>
          </div>
        </div>
        {isOpen && (
          <div className="lg:hidden">
            <div className="px-2 pb-3 fixed z-20 bg-white w-[100vw] h-[100vh] top-0 left-0 pt-12 text-center">
              <div className=" space-y-1">
                <a
                  href="#"
                  className="text-gray-800 block hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                  onClick={() => {
                    navigate("/");
                    setIsOpen(false);
                  }}
                >
                  Home
                </a>
                <a
                  href="#"
                  className="text-gray-800 block hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                  onClick={() => {
                    navigate("/about-us");
                    setIsOpen(false);
                  }}
                >
                  About Us
                </a>
                <a
                  href="#"
                  className="text-gray-800 block hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                  onClick={() => {
                    navigate("/volunteer");
                    setIsOpen(false);
                  }}
                >
                  Volunteer
                </a>
                <a
                  href="#"
                  className="text-gray-800 block hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                  onClick={() => {
                    navigate("/announcements");
                    setIsOpen(false);
                  }}
                >
                  Announcements
                </a>
                {authenticated && (
                  <a
                    href="#"
                    className="text-gray-800 block hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                    onClick={() => {
                      navigate("/admin");
                      setIsOpen(false);
                    }}
                  >
                    Admin
                  </a>
                )}
                <a
                  href="#"
                  className="text-gray-800 block hover:bg-gray-200 px-3 py-2 rounded-md text-sm font-medium"
                  onClick={() => {
                    navigate("/summer-camp");
                    setIsOpen(false);
                  }}
                >
                  Summer Camp
                </a>
              </div>
              <a
                href="#"
                className="text-white block w-[95%] mt-3 text-center py-2  rounded-md text-sm font-medium bg-red-500 hover:bg-red-700 text-white font-bold py-2 rounded border border-red-500 max-w-[300px] mx-auto "
                onClick={() => {
                  navigate("/student-registration");
                  setIsOpen(false);
                }}
              >
                Register
              </a>
            </div>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
