import React, { useEffect, useState } from "react";
import { supabase } from "../../server";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";

const Schools = ({ className }) => {
  const navigate = useNavigate();

  const [schools, setSchools] = useState([]);
  const [loading, setLoading] = useState(true);

  const querySchool = async () => {
    const { data, error } = await supabase
      .from("school")
      .select(`*`)
      .order("name", { ascending: true });
    if (error) return error;
    else return data;
  };

  useEffect(() => {
    querySchool().then((res) => {
      setSchools(res);
      setLoading(false);
    });
  }, []);

  const selectSchool = (school) => {
    navigate(`/admin/school/${school.id}`);
  };

  return (
    <div className={`${className} flex flex-col px-10 max-w-[1400px] mx-auto`}>
      <button
        onClick={() => navigate("/admin/new-school")}
        className="mb-4 transition duration-200 ease-in-out cursor-pointer bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded border border-green-500 w-[150px]"
      >
        Add New School
      </button>
      {loading ? (
        <p className="mx-auto">Loading...</p>
      ) : (
        <div className="grid grid-cols-4 gap-4">
          {schools.map((school, index) => (
            <div key={school.id} onClick={() => selectSchool(school)}>
              <div className="bg-white p-2 cursor-pointer h-[80px]">
                <h1>{school.name}</h1>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default styled(Schools)``;
