import React, { useEffect, useRef, useState } from "react";
import { supabase } from "../../server";
import { useNavigate, useParams } from "react-router-dom";

const EditSchool = () => {
  const navigate = useNavigate();

  const { schoolId } = useParams();

  const [school, setSchool] = useState({});
  console.log(school);

  const querySchool = async () => {
    const { data, error } = await supabase
      .from("school")
      .select(`*`)
      .eq("id", schoolId);
    if (error) return error;
    else return data;
  };

  useEffect(() => {
    querySchool().then((res) => {
      console.log(res);
      setSchool(res[0]);
    });
  }, []);

  const nameRef = useRef();
  const emailRef = useRef();
  const phoneRef = useRef();
  const dayOfOperation = useRef();
  const hours = useRef();
  const addressRef = useRef();
  const cityRef = useRef();
  const postalCodeRef = useRef();
  const provinceRef = useRef();
  const regionRef = useRef();
  const latitudeRef = useRef();
  const longitudeRef = useRef();

  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const defaultDay = school?.hoursOfOperation
    ? Object.keys(school?.hoursOfOperation)[0]
    : "";

  const handleSave = async () => {
    setSaving(true);

    // Collect values from refs
    const updatedFields = {
      name: nameRef.current.value,
      email: emailRef.current.value,
      phone: phoneRef.current.value,
      hoursOfOperation: {
        [dayOfOperation.current.value]: hours.current.value,
      },
      address: addressRef.current.value,
      city: cityRef.current.value,
      postalCode: postalCodeRef.current.value,
      province: provinceRef.current.value,
      region: regionRef.current.value,
      latitude: latitudeRef.current.value,
      longitude: longitudeRef.current.value,
    };

    const { error } = await supabase
      .from("school")
      .update(updatedFields)
      .eq("id", schoolId);

    if (error) {
      console.error("Failed to update school:", error);
      // You can also notify the user of the error here, if needed.
    } else {
      console.log(`School with id ${schoolId} updated successfully!`);
    }

    navigate("/admin");
  };

  const deleteSchool = async (school) => {
    setDeleting(true);

    const { id } = school; // Extract the school's id
    const { error } = await supabase.from("school").delete().eq("id", id);

    if (error) {
      console.error("Failed to delete school:", error);
    } else {
      console.log(`School with id ${id} deleted successfully!`);
    }

    navigate("/admin");
  };

  return (
    <div className="flex flex-col max-w-[700px] mx-auto">
      <h2 className="text-xl mb-4">Edit School - {school?.name}</h2>

      <div className="general-details mb-4 grid grid-cols-2 gap-1">
        <label>Name:</label>
        <input ref={nameRef} defaultValue={school?.name} />

        <label>Email:</label>
        <input ref={emailRef} defaultValue={school?.email} />

        <label>Phone:</label>
        <input ref={phoneRef} defaultValue={school?.phone} />

        <label>Hours of Operation:</label>
        <div>
          <input
            ref={dayOfOperation}
            defaultValue={
              school?.hoursOfOperation && Object.keys(school?.hoursOfOperation)
            }
          />
          <input
            ref={hours}
            defaultValue={school?.hoursOfOperation?.[defaultDay]}
          />
        </div>
      </div>

      <div className="location-details mb-4 grid grid-cols-2 gap-1">
        <label>Address:</label>
        <input ref={addressRef} defaultValue={school?.address} />

        <label>City:</label>
        <input ref={cityRef} defaultValue={school?.city} />

        <label>Postal Code:</label>
        <input ref={postalCodeRef} defaultValue={school?.postalCode} />

        <label>Province:</label>
        <input ref={provinceRef} defaultValue={school?.province} />

        <label>Region:</label>
        <input ref={regionRef} defaultValue={school?.region} />

        <label>Latitude:</label>
        <input ref={latitudeRef} defaultValue={school?.latitude} />

        <label>Longitude:</label>
        <input ref={longitudeRef} defaultValue={school?.longitude} />
      </div>

      <div className="flex justify-end">
        <button
          className="transition duration-200 ease-in-out bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded mr-2 "
          onClick={() => deleteSchool(school)}
          disabled={saving || deleting}
        >
          {deleting ? "Deleting..." : "Delete"}
        </button>
        <button
          onClick={handleSave}
          className="transition duration-200 ease-in-out cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500 w-[150px]"
          disabled={saving || deleting}
        >
          {saving ? "Saving..." : "Save"}
        </button>
      </div>
    </div>
  );
};

export default EditSchool;
