import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../server";
import { useSelector, useDispatch } from "react-redux";
import { selectAnnouncement } from "../../slices/announcementSlice";
import "react-quill/dist/quill.snow.css";
import "react-dropzone-uploader/dist/styles.css";
import NewPost from "./new-post";

async function insertPublicAnnouncement(title, entry, files) {
  const { data, error } = await supabase
    .from("announcement")
    .insert({
      title,
      entry,
      images: files.map((file) => encodeURI(file.file.name)),
    })
    .select();

  return data[0];
}

async function queryAnnouncement() {
  const { data, error } = await supabase
    .from("announcement")
    .select(`*`)
    .order("id", { ascending: false });

  if (error) return error;
  else return data;
}

const Announcements = ({ className }) => {
  // Stops playing the video after 10 seconds
  const videoRef = useRef(null);

  useEffect(() => {
    const timer = setTimeout(() => {
      if (videoRef.current && !videoRef.current.paused) {
        videoRef.current.pause();
      }
    }, 10000);

    return () => clearTimeout(timer);
  }, []);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Checking if user is logged in
  const authenticated =
    JSON.parse(localStorage.getItem("sb-lqzahgakgtrswulxeedj-auth-token"))?.user
      ?.aud === "authenticated";

  const screen = useSelector((state) => state?.screen);

  const [announcements, setAnnouncements] = useState([]);
  const title = useRef();

  useEffect(() => {
    queryAnnouncement().then((res) => {
      setAnnouncements(res);
    });
  }, []);

  return (
    <div className="w-9/10 max-w-7xl mx-auto">
      <h2 className="mt-16 lg:mt-[50px] text-3xl xl:text-5xl font-bold mx-auto text-center">
        Announcements
      </h2>
      <p className="text-center mt-4 max-w-[800px] mx-auto px-4">
        Stay updated with our latest news, events, and essential notices.
      </p>
      <p className="text-center mb-4 max-w-[800px] mx-auto px-4">
        Check this section regularly to never miss out on important information.
      </p>

      {authenticated && screen !== "mobile" && (
        <NewPost
          title={title}
          queryAnnouncement={queryAnnouncement}
          setAnnouncements={setAnnouncements}
        />
      )}

      {announcements.length > 0 && (
        <>
          <div className="">
            <div className="mx-auto max-w-7xl px-6 lg:px-8">
              <div className="mx-auto mt-10 grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 border-t border-gray-200 lg:mx-0 lg:max-w-none lg:grid-cols-3">
                {announcements.map((announcement) => (
                  <div
                    onClick={() => {
                      dispatch(selectAnnouncement(announcement));
                      navigate(`/announcements/${announcement.id_new}`);
                    }}
                    className="cursor-pointer"
                    key={announcement.id}
                  >
                    {["mp4", "quicktime", ".mov"].includes(
                      announcement?.images[0].split(".").slice(-1)[0]
                    ) ? (
                      <video
                        src={`https://storage.googleapis.com/kcsa/announcement-image/${announcement.id}-${announcement.images[0]}`}
                        alt=""
                        className="object-cover min-h-[300px] w-full rounded-t-md cursor-pointer transform transition-transform hover:scale-105 duration-500 ease-in-out"
                        autoPlay
                        ref={videoRef}
                        muted
                        onClick={() => {
                          dispatch(selectAnnouncement(announcements[0]));
                          navigate(`/announcements/${announcements[0].id_new}`);
                        }}
                      />
                    ) : (
                      <div className="overflow-hidden relative">
                        <img
                          src={`https://storage.googleapis.com/kcsa/announcement-image/${announcement.id}-${announcement.images[0]}`}
                          alt=""
                          className="object-cover min-h-[300px] w-full rounded-t-md cursor-pointer transform transition-transform hover:scale-105 duration-500 ease-in-out"
                          onClick={() => {
                            dispatch(selectAnnouncement(announcements[0]));
                            navigate(
                              `/announcements/${announcements[0].id_new}`
                            );
                          }}
                        />
                      </div>
                    )}
                    <article
                      key={announcement.id_new}
                      className="flex  flex-col items-start bg-white p-4 rounded-b-md min-h-[250px]"
                    >
                      <div className="flex items-center gap-x-4 text-xs">
                        <time className="text-gray-500">
                          {announcement.timestamp.slice(0, 10)}
                        </time>
                      </div>
                      <div className="group relative">
                        <h3 className="line-clamp-2 mt-3 text-lg font-semibold leading-6 text-gray-900 group-hover:text-gray-600">
                          <a href={announcement.href}>
                            <span className="absolute inset-0" />
                            {announcement.title}
                          </a>
                        </h3>
                        <p className="mt-5 line-clamp-3 text-sm leading-6 text-gray-600">
                          {announcement.entry.replace(/<[^>]*>/g, "")}
                        </p>
                      </div>

                      <p
                        className="text-xs lg:text-md  max-w-[600px] text-red-500 font-medium mt-4 cursor-pointer "
                        onClick={() => {
                          dispatch(selectAnnouncement(announcements[0]));
                          navigate(`/announcements/${announcements[0].id_new}`);
                        }}
                      >
                        Read more
                      </p>
                    </article>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default styled(Announcements)``;
