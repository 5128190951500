import React from "react";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import aboutUsImage from "./images/about-us.jpeg";
import { useMediaQuery } from "react-responsive";
import Button from "../common-component/button";

const AboutUs = ({ className }) => {
  const navigate = useNavigate();

  return (
    <div className={`${className} about-us mx-auto text-center`}>
      <h2 className="mt-16 lg:mt-[100px] text-3xl xl:text-5xl font-bold mx-auto w-[90vw]">
        About Us
      </h2>

      <div className="w-[90vw] mx-auto text-center">
        <p className="  mt-5 max-w-[1000px] text-center mx-auto">
          Welcome to the Korean Canadian Schools Association. We are committed
          to providing students of all backgrounds an opportunity at Korean
          education and culture.
        </p>

        <p className=" mt-5 mb-8 max-w-[1000px] mx-auto">
          Whether you're a beginner looking to start your journey or seeking
          advanced instruction, KCSA offers tailored courses to meet your needs.
          Join us and explore the beauty of the Korea's history and Korean
          language.
        </p>

        {/* <Button
          name="About Us"
          onClick={() => navigate("/about-us")}
          color="black"
        /> */}

        <button
          className="transition duration-200 ease-in-out bg-transparent hover:bg-red-500 text-red-500 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded mr-2 mb-10 lg:mb-[100px]"
          onClick={() => navigate("/about-us")}
        >
          Learn More
        </button>
      </div>

      <img
        src={aboutUsImage}
        alt="Learn more about Korean Canadian Schools Association"
        className="w-[100vw] rounded-md mx-auto max-h-[90vh] object-cover"
      />
      {/* <div className="background">
        <div></div>
      </div>

      <div className="black-fade-background"></div> */}
    </div>
  );
};

export default styled(AboutUs)`
  position: relative;
  display: flex;
  flex-direction: column;
  border: none;
  overflow: hidden;

  .background {
    display: flex;
    flex-direction: column;
    margin-top: -10vw;
    max-height: 900px;

    > div {
      background: linear-gradient(to top, transparent 50%, white);
      position: absolute;
      height: 250px;
      width: 100vw;
    }

    img {
      object-fit: cover;
      width: 100%;
      min-height: 500px;
      /* margin-bottom: 10px; */
    }
  }

  /* .black-fade-background {
    position: absolute;
    bottom: -2px;
    height: 150px;
    width: 100%;
    background: linear-gradient(
      to top,
      rgb(11 11 11) 10%,
      rgba(0, 0, 0, 0.5) 50%,
      rgba(0, 0, 0, 0) 100%
    );
    border-bottom: none;
  } */

  .description {
    font-size: 5vw;
    font-weight: 400;
    line-height: 150%;
    color: #020a06;
    margin-bottom: 40px;
    margin: 10vw 0;
  }

  @media only screen and (min-width: 900px) {
    .description {
      margin-top: 150px;
      margin-bottom: 85px;
    }
  }

  @media only screen and (min-width: 400px) {
    .description {
      font-size: 18px;
      max-width: 800px;
    }
  }

  button {
    width: fit-content;
  }
`;
