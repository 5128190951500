import React from "react";
import styled from "styled-components";

const Button = ({ className, name, onClick }) => {
  return (
    <button className={`${className}`} onClick={onClick}>
      {name}
    </button>
  );
};

export default styled(Button)`
  padding: 6px 14vw;
  border: ${(props) =>
    props.color ? `1.5px solid ${props.color}` : `1.5px solid black`};
  border-radius: 10px;

  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  font-style: normal;
  font-weight: 500;
  font-size: 5vw;
  line-height: 150%;
  text-align: center;
  color: ${(props) => props.color || "black"};
  background: ${(props) => (props.color === "white" ? "transparent" : "white")};

  :hover {
    color: ${(props) => (props.color === "white" ? "black" : "white")};
    background: ${(props) => (props.color === "white" ? "white" : "black")};
  }

  @media screen and (min-width: 350px) {
    font-size: 18px;
    padding: 6px 60px;
  }
`;
