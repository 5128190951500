import React from "react";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import girlHoldingBooks from "./images/student/girl-holding-books.png";
import selfie from "./images/teacher/selfie.png";

const Volunteer = ({ className }) => {
  const navigate = useNavigate();

  const screen = useSelector((state) => state.screen);

  return (
    <div className={`${className} mx-auto text-center mb-[100px] px-4`}>
      <h2 className="mt-16 lg:mt-[50px] text-3xl xl:text-5xl font-bold mx-auto">
        Volunteer Opportunities
      </h2>
      <p className="text-center mt-4 mb-10 lg:text-lg">
        Empowering our future generations together
      </p>

      <img
        src={girlHoldingBooks}
        alt=""
        className="rounded-md max-w-[1400px] max-h-[700px] mx-auto w-full object-cover"
      />

      <p className="px-4 lg:px-0 mt-4 max-w-[800px] text-center mx-auto">
        “From organizing events to working with other students and community
        members, I have learned so much about teamwork, communication, and
        leadership.”
      </p>
      <p className="text-sm font-bold mt-2">
        - Ethnia, Summer Camp Student Volunteer -
      </p>

      <button
        onClick={() => navigate("/volunteer/student")}
        className="transition duration-200 ease-in-out cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500 mt-5"
      >
        Volunteer as a Student
      </button>

      <img
        src={selfie}
        alt=""
        className="rounded-md w-full max-w-[1400px] max-h-[700px] mx-auto object-cover mt-20"
      />

      <p className="px-4 lg:px-0 mt-4 max-w-[800px] text-center mx-auto">
        “Being able to give back and make a positive impact in the lives of
        these young people has been incredibly inspiring and has truly changed
        me for the better.”
      </p>
      <p className="text-sm font-bold mt-2">
        - Jason, Grade 8 Teacher Volunteer -
      </p>

      <button
        onClick={() => navigate("/volunteer/teacher")}
        className="transition duration-200 ease-in-out cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500 mt-5"
      >
        Volunteer as a Teacher
      </button>
    </div>
  );
};

export default styled(Volunteer)``;
