import React from "react";
import styled from "styled-components";
import logo from "./images/logo-with-name.png";

const MobileAlert = ({ className }) => {
  return (
    <div className={className}>
      <img src={logo} alt="" />
      <p>The mobile page is coming soon!</p>
      <p>Please view this app on a desktop for now.</p>
      <p>Thank you for your patience!</p>
    </div>
  );
};

export default styled(MobileAlert)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100vw;

  img {
    width: 80vw;
  }

  p {
    font-size: 14px;
  }

  p:nth-child(2) {
    margin-top: 10px;
  }

  p:nth-child(4) {
    margin-bottom: 150px;
  }
`;
