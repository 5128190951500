import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import CollectPersonInfo from "./collect-person-info";
import ExplainReason from "./explain-reason";
import { supabase } from "../../../server";
import Complete from "./complete";

const Application = ({ className }) => {
  const navigate = useNavigate();

  const { applicantType, applicationStep } = useParams();

  const [fullName, setFullName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [reason, setReason] = useState("");
  const [file, setFile] = useState();

  const onSubmit = async () => {
    try {
      const { data: volunteer, error } = await supabase
        .from("volunteerRegistration")
        .insert({
          name: fullName,
          email,
          phone: phoneNumber,
          reason,
        })
        .select();

      if (error) {
        throw new Error(`Failed to insert volunteer: ${error.message}`);
      }

      const apiUrl =
        process.env.NODE_ENV === "development"
          ? "http://localhost:3001/google-cloud/get-signed-url"
          : "https://korean-school.herokuapp.com/google-cloud/get-signed-url";

      let request;
      try {
        request = await fetch(apiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fileName: `${volunteer[0].id}.pdf`,
            contentType: file.type,
            type: "volunteer",
          }),
        });
      } catch (e) {
        throw new Error(`Failed to request signed URL: ${e.message}`);
      }

      if (!request.ok) {
        throw new Error(`Server responded with status ${request.status}`);
      }

      const response = await request.json();
      const signedUrl = response.url;

      let uploadResponse;

      try {
        uploadResponse = await fetch(signedUrl, {
          method: "PUT",
          headers: {
            "Content-Type": file.type,
          },
          body: file,
        });
      } catch (e) {
        throw new Error(`Failed to upload file: ${e.message}`);
      }

      if (!uploadResponse.ok) {
        throw new Error(
          `Failed to upload file, server responded with status ${uploadResponse.status}`
        );
      } else {
        const apiUrl =
          process.env.NODE_ENV === "development"
            ? "http://localhost:3001/email/volunteer-application"
            : "https://korean-school.herokuapp.com/email/volunteer-application";

        // Send confirmation email to user and send application email to admin
        fetch(apiUrl, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            fullName,
            email,
            phoneNumber,
            reason,
            filename: `${volunteer[0].id}.pdf`,
          }),
        }).then(() => navigate("/volunteer/student/success"));
      }
    } catch (e) {
      console.error(e);
      // TODO: Report error to your error reporting service
    }
  };

  if (applicationStep === "collect-person-info") {
    return (
      <div className={className}>
        <CollectPersonInfo
          fullName={fullName}
          setFullName={setFullName}
          email={email}
          setEmail={setEmail}
          phoneNumber={phoneNumber}
          setPhoneNumber={setPhoneNumber}
        />
      </div>
    );
  } else if (applicationStep === "explain-reason") {
    return (
      <div className={className}>
        <ExplainReason
          reason={reason}
          setReason={setReason}
          setFile={setFile}
          onSubmit={onSubmit}
          file={file}
        />
      </div>
    );
  } else if (applicationStep === "success") {
    return (
      <div className={className}>
        <Complete />
      </div>
    );
  }
};

export default styled(Application)`
  max-width: 700px;
  overflow: hidden;
  margin: auto;
`;
