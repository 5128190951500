const createSlice = require("@reduxjs/toolkit").createSlice;

const initialState = {};

const announcementSlice = createSlice({
  name: "announcement",
  initialState,
  reducers: {
    selectAnnouncement: (state, { payload }) => {
      state.id = payload.id;
      state.title = payload.title;
      state.entry = payload.entry;
      state.timestamp = payload.timestamp;
      state.images = payload.images;
    },
  },
});

export const { selectAnnouncement } = announcementSlice.actions;
export default announcementSlice.reducer;
