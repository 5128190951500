import React, { useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import BlueMobileButton from "../../Reusable/blue-mobile-button";
import cloud from "../images/cloud.png";

const ExplainReason = ({
  className,
  setFile,
  onSubmit,
  setReason,
  reason,
  file,
}) => {
  const { applicantType } = useParams();

  const handleFileUpload = (e) => {
    setFile(e.target.files[0]);
  };

  return (
    <div
      className={`${className} bg-white p-[50px] drop-shadow-lg rounded-md mt-[50px] mx-8`}
    >
      <div>
        <p>Step 2 of 2</p>
        {applicantType === "student" ? (
          <h2>Student Volunteer Application</h2>
        ) : (
          <h2>Teacher Volunteer Application</h2>
        )}
      </div>

      <p className="question">
        Why do you want to be a{" "}
        {applicantType === "student" ? "student" : "teacher"} volunteer?
      </p>
      <textarea
        rows="4"
        cols="50"
        placeholder="I want to be a volunteer because..."
        onChange={(e) => setReason(e.target.value)}
        className="border-2 rounded-md"
      ></textarea>
      <br />

      {applicantType === "teacher" && (
        <label htmlFor="upload" className="custom-file-upload">
          <img src={cloud} alt="" />
          Upload Resume
          <input
            type="file"
            id="upload"
            accept=".pdf"
            onChange={handleFileUpload}
          />
        </label>
      )}

      <div className="file-name">{file && file.name}</div>
      <br />
      <BlueMobileButton
        name="Submit"
        disabled={reason === "" || (applicantType === "teacher" && !file)}
        onClick={onSubmit}
      />
    </div>
  );
};

const StyledExplainReason = styled(ExplainReason)`
  display: flex;
  flex-direction: column;
  /* gap: 35px; */

  h2 {
    margin-bottom: 10px;
    margin-top: 0;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 130%;
    /* or 36px */

    color: #000a23;
  }

  p {
    margin: 0;
    font-weight: 500;
    font-size: 10px;
    line-height: 22px;
    /* identical to box height, or 220% */

    color: #191919;

    opacity: 0.8;
  }

  .question {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    color: #191919;

    opacity: 0.8;
    margin-top: 25px;
    margin-bottom: 2.5px;
  }

  textarea {
    width: 100%;
    margin-bottom: 10px;
    resize: vertical;
    font-family: "Inter";
    padding: 5px;
    box-sizing: border-box;
  }

  .custom-file-upload {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 5px;
    background-color: #3b82f6;
    color: white;
    padding: 8px 16px;
    cursor: pointer;
    border-radius: 4px;
    width: 100%;
    text-align: center;
    box-sizing: border-box;
    background: #f4f4f4;
    border-radius: 5px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    /* identical to box height, or 157% */

    text-align: center;

    color: #00316e;

    img {
      height: 16px;
      padding-top: 2px;
    }
  }

  input[type="file"] {
    display: none;
  }

  .file-name {
    width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
    box-sizing: border-box;
    font-size: 12px;
  }
`;

export default StyledExplainReason;
