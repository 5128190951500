import React from "react";
import styled from "styled-components";

import treesBottom from "./trees-bottom.png";

const Questions = ({ className }) => {
  return (
    <div className={className}>
      <h4>Questions?</h4>
      <p>We're here to help! Contact us.</p>
      <p>E-Mail: info@koreanschools.org</p>
      <p>Phone: 647-746-3706</p>

      <img src={treesBottom} alt="" />
    </div>
  );
};

export default styled(Questions)`
  margin-top: 87px;
  display: flex;
  flex-direction: column;
  align-items: center;

  img {
    width: 100%;
    object-fit: cover;
  }

  h4 {
    margin-bottom: 10px;
    font-size: 20px;
  }

  @media only screen and (min-width: 768px) {
    h4 {
      font-size: 25px;
    }

    p {
      font-size: 18px;
    }
  }
`;
