import React, { useEffect } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { checkAuth } from "../../slices/userSlice";

//Components
import MainSection from "./main-section";
import AboutUs from "./about-us";
import AnnouncementsSlide from "./announcements-slide";
import Register from "./register";
import Volunteer from "./volunteer-section";

const HomePage = ({ className }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      dispatch(checkAuth());
    })();
  }, []);

  return (
    <div className={`${className}`}>
      <MainSection />

      <AboutUs />

      <Register />

      <AnnouncementsSlide />

      <Volunteer />
    </div>
  );
};

export default styled(HomePage)`
  margin: auto;
  max-width: 2500px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;

  > img {
    margin: auto;
    width: 100%;
  }

  .dark-mask {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.05); /* 50% opacity black color */
    pointer-events: none;
  }
`;
