import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { supabase } from "../../server";
import { useMediaQuery } from "react-responsive";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { selectAnnouncement } from "../../slices/announcementSlice";

import { formatTimestamp } from "../Announcements/helper";

export default function AnnouncementsSlide({ className }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const isMobile = useMediaQuery({ query: "(max-width: 1200px)" });

  const [selectionIndex, setSelectionIndex] = useState(0);
  const [announcements, setAnnouncements] = useState([]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase
        .from(`announcement`)
        .select()
        .order("id", { ascending: false })
        .limit(6);

      setAnnouncements(data);
      setSelectionIndex(data[0].id);
    })();
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1200 },
      items: 1,
    },
    tablet: {
      breakpoint: { max: 1199, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  return (
    <Desktop isMobile={isMobile}>
      <div className="w-[90%] mx-auto text-center mt-10 lg:my-[100px]">
        <h2 className="text-3xl xl:text-5xl font-bold lg:text-4xl">Blog</h2>

        <p className="lg:text-md mt-5 mb-5 max-w-[800px] mx-auto">
          Stay informed on all things KCSA! As an umbrella organization joining
          many Korean Schools across Canada, we created a centralized source for
          all Korean school related news. Whether you're a parent seeking
          opportunities for your child or a student looking for scholarship
          options, this page has all the latest details and announcements.
        </p>
      </div>

      <Carousel
        responsive={responsive}
        // customLeftArrow={<CustomLeftArrow />}
        // customRightArrow={<CustomRightArrow />}
        slidesToSlide={1}
        // autoPlay={true}
        // autoPlaySpeed={8000}
        infinite={true}
        swipeable={false}
        draggable={false}
      >
        {announcements.length > 0 &&
          announcements
            .filter((ann) => ann.id === 5)
            .map((announcement) => (
              <div
                onClick={() => setSelectionIndex(announcement.id)}
                key={announcement.id}
                className="announcement overflow-hidden"
              >
                <img
                  src={`https://storage.googleapis.com/kcsa/announcement-image/${announcement.id}-${announcement.images}`}
                  alt=""
                  className="max-h-[700px] object-cover mt-3 rounded-md w-[90vw] max-w-[1400px] mx-auto "
                  key={announcement.id}
                />

                <div className="px-4">
                  <h6 className="mt-4 text-xs mb-2">
                    {formatTimestamp(announcement.timestamp)}
                  </h6>
                  <p className="text-sm lg:text-lg lg:mt-0 max-w-[600px] font-medium ">
                    {announcement.title}
                  </p>
                  <p
                    className="text-xs lg:text-md mt-2 mb-5  max-w-[600px] text-red-500 font-bold cursor-pointer "
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(selectAnnouncement(announcement));
                      navigate(`/announcements/${announcement.id_new}`);
                    }}
                  >
                    {" "}
                    Read more
                  </p>
                </div>
              </div>
            ))}
      </Carousel>
    </Desktop>
  );
}

const Desktop = styled.div`
  margin: auto;
  /* width: 1100px; */
  max-width: 100vw;

  .image-container {
    overflow: hidden;
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;
    position: relative;
  }

  .line {
    position: absolute;
    border-top: 2px solid black;
    width: 80%;
  }

  .selected {
    border-top: 5px solid black;
  }

  .react-multi-carousel-item {
    display: flex;
    justify-content: center;
  }

  .react-multi-carousel-track > li {
    height: fit-content;
  }

  .react-multi-carousel-list {
    position: unset !important;
  }

  .react-multiple-carousel__arrow--left {
    left: calc((100% - 1100px) / 2 - 50px);
  }

  .react-multiple-carousel__arrow--right {
    right: calc((100% - 1100px) / 2 - 50px);
  }

  .hide {
    display: none;
  }

  button {
    display: none;
  }
`;
