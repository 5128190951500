import React from "react";
import styled from "styled-components";

import { useNavigate, useParams } from "react-router-dom";

const ApplyNow = ({ className }) => {
  const navigate = useNavigate();
  const { applicantType } = useParams();

  const onClick = () => {
    applicantType === "student"
      ? navigate("/volunteer/student/collect-person-info")
      : navigate("/volunteer/teacher/collect-person-info");
  };

  return (
    <div className={className}>
      {applicantType === "student" && (
        <div>
          <h2 className="font-bold my-4 text-xl">
            Opportunities for High School & Post-Secondary Students
          </h2>

          <p className="text-center max-w-[800px] mx-auto mb-10">
            {`Join KCSA's student volunteer team and make a real impact in your community while gaining valuable skills and experiences. Not only will you be making a difference, but you'll also have the opportunity to earn recognition and rewards for your service, including high school volunteer hour credits, certificates of achievement, and even scholarships for post-secondary education.

Be a part of something bigger and help shape the future for Korean-Canadians.`}
          </p>
        </div>
      )}

      {applicantType === "teacher" && (
        <div>
          <h2 className="font-bold my-4 text-xl">Opportunities for Teachers</h2>

          <p className="text-center max-w-[800px] mx-auto mb-10">
            {`Volunteering as a teacher with the Korean Canadian Association of Schools (KCSA) is a rewarding and fulfilling experience. You will have the opportunity to shape the minds of young students and inspire a love of learning, and gain valuable skills that will benefit you in your teaching career. Additionally, by giving back to your community and working to promote cultural awareness and understanding, you will be making a real difference in the lives of students and families. 

Become a teacher volunteer with KCSA and join a dedicated team of educators making a positive impact in the world.`}
          </p>
        </div>
      )}

      <button
        onClick={onClick}
        className=" transition duration-200 ease-in-out cursor-pointer bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500 z-10 "
      >
        Volunteer Today
      </button>
    </div>
  );
};

export default styled(ApplyNow)``;
