import React from "react";
import styled from "styled-components";

const Teachers = () => {
  console.log("auth page");

  return <div>Teachers</div>;
};

export default styled(Teachers)`
  margin-top: 100px;
`;
