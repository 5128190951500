import React from "react";
import styled from "styled-components";
import CampDetails from "./camp-details";
// import Footer from "../footer";
import Questions from "./questions";
import Registration from "./registration";
import Video from "./video";

const SummperCamp = ({ className }) => {
  const onClick = () =>
    window
      .open(
        "https://docs.google.com/forms/d/e/1FAIpQLScCof-RVxMJ4qxWOtFmJdGgLTbGQ-O5-1hgaKTmRK34WtCnnw/viewform ",
        "_blank"
      )
      .focus();
  return (
    <div className={className}>
      <Video />

      <Registration />

      <button onClick={onClick}>Register Today</button>

      <CampDetails />

      <button onClick={onClick}>Registration Form</button>
      <p>Clicking this button will open a new Google Forms page</p>

      <Questions />

      {/* <Footer /> */}
    </div>
  );
};

export default styled(SummperCamp)`
  background: linear-gradient(180deg, #dae1cb 0%, rgba(218, 225, 203, 0) 100%);
  min-height: 100vh;

  display: flex;
  flex-direction: column;
  align-items: center;

  /* font-family: "Fuzzy Bubbles"; */

  button:nth-of-type(1) {
    /* font-family: "Fuzzy Bubbles"; */

    padding: 5px 20px 4px;
    font-weight: 700;
    font-size: 20px;
    line-height: -1px;

    display: flex;
    align-items: center;
    text-align: center;
    background: transparent;
    border: 2px solid #000000;
    border-radius: 90px;
    color: black;

    :hover {
      background: black;
      color: white;
      cursor: pointer;
    }
  }

  button:nth-of-type(2) {
    font-family: "Fuzzy Bubbles";

    background: #5d6156;
    color: white;
    font-weight: 400;
    font-size: 14px;
    line-height: -1px;
    padding: 8px 35px 6px;
    border-radius: 90px;
    border: none;
    margin-top: 25px;
    cursor: pointer;
    width: 285px;
    box-sizing: border-box;
  }

  > p {
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 12px;
    text-align: center;
    margin-top: 2.5px;
  }

  @media only screen and (min-width: 768px) {
    button:nth-of-type(1),
    button:nth-of-type(2) {
      font-size: 20px;
    }
  }
`;
