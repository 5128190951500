import React from "react";
import styled from "styled-components";
import Image from "./images/volunteer.jpg";
import { useNavigate } from "react-router-dom";
import Button from "../common-component/button";

const Volunteer = ({ className }) => {
  const navigate = useNavigate();

  return (
    <div className={`${className} mt-10 lg:mt-[100px]`}>
      <img
        src={Image}
        alt="Volunteer at a Korean Education School today"
        className="rounded-md w-[100vw] mx-auto max-h-[800px]"
      />

      <div className="mx-auto text-center mt-[50px] max-w-[1000px] ">
        <h2 className="text-3xl font-bold mx-auto lg:text-4xl xl:text-5xl">
          Volunteer Today!
        </h2>

        <p className="mt-5 mb-5">
          Become a student or teacher volunteer with KCSA and make a difference
          in your community. Gain valuable skills, meet like-minded individuals
          and make an impact.
        </p>

        <button
          onClick={() => navigate("volunteer")}
          className="transition duration-200 ease-in-out bg-red-500 hover:bg-red-700 text-white font-bold py-2 px-4 rounded border border-red-500 mb-20"
        >
          Volunteer
        </button>

        {/* <Button
          name="Volunteer"
          onClick={() => navigate("/volunteer")}
          color="white"
        /> */}
      </div>
    </div>
  );
};

export default styled(Volunteer)`
  overflow: hidden;
  position: relative;
  width: 100%;

  img {
    top: 0;
    height: 80vw;
    object-fit: cover;
  }

  > div {
    width: 90%;
  }

  @media only screen and (min-width: 500px) {
    > div {
      padding: 0px 40px;
    }
  }
`;
