import React, { useContext } from "react";
import styled from "styled-components";
import TextField from "@mui/material/TextField";
import { useForm, Controller } from "react-hook-form";
import { supabase } from "../../server";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { checkAuth } from "../../slices/userSlice";
import Button from "../common-component/next-back-button";

const SignIn = ({ className }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  let [searchParams, setSearchParams] = useSearchParams();
  const fromEmailConfirmationLink = searchParams.get("confirmation");

  const {
    register,
    handleSubmit,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const onSubmit = async () => {
    (async () => {
      const { data, error } = await supabase.auth.signInWithPassword({
        email: watch("email"),
        password: watch("password"),
      });

      if (!error) {
        dispatch(checkAuth());
        navigate("/");
      }
    })();
  };

  return (
    <div className={`${className} mt-[100px] mx-auto bg-white rounded-md`}>
      {fromEmailConfirmationLink === "true" && (
        <div>Email confirmed successfully. Please log in.</div>
      )}

      <h1 className="font-bold text-[28px] font-medium mb-6">Admin Login</h1>

      <form onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <TextField
              onChange={onChange}
              value={value}
              required
              id="outlined-required"
              label="Email"
            />
          )}
        />

        <Controller
          control={control}
          name="password"
          render={({
            field: { onChange, onBlur, value, name, ref },
            fieldState: { invalid, isTouched, isDirty, error },
            formState,
          }) => (
            <TextField
              onChange={onChange}
              value={value}
              required
              id="outlined-required"
              label="Password"
              type="password"
            />
          )}
        />

        <Button name="Sign In" />
      </form>
    </div>
  );
};

export default styled(SignIn)`
  width: 500px;
  padding-top: 70px;
  height: 70vh;

  form {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 200px;
  }

  @media only screen and (min-width: 700px) {
    padding: 50px;
    box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
  }
`;
