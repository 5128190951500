import styled from "styled-components";
import { TextField } from "@mui/material";
import React from "react";
import Button from "../common-component/next-back-button";

const Address = ({ className, setPage, register, trigger, errors, watch }) => {
  const data = watch();

  return (
    <div className={className}>
      <div className="pt-0 mt-4">
        <h4 className="text-3xl lg:text-4xl font-bold leading-[1.2] text-center relative ">
          School Registration
        </h4>
        <p className="text-sm sm:text-base px-2 text-center mt-4 whitespace-pre-wrap">{`Thanks!\nJust a few personal details for verification purposes.`}</p>
      </div>
      <div className="form bg-white p-8 rounded-lg mx-auto drop-shadow-lg mt-8">
        <div>
          <p className="text-xs">School Registration - Step 3 of 4</p>
          <h1 className="section">Address</h1>
        </div>

        <TextField
          id="outlined-full-name"
          label="Address"
          variant="outlined"
          name="address"
          error={Boolean(errors.address)}
          helperText={errors.address && errors.address.message}
          InputProps={{
            inputProps: {
              ...register("address", {
                required: "This field is required",
              }),
            },
          }}
          placeholder="ex. 1234 Smith Street"
        />

        <TextField
          id="outlined-full-name"
          label="City"
          variant="outlined"
          name="city"
          error={Boolean(errors.city)}
          helperText={errors.city && errors.city.message}
          InputProps={{
            inputProps: {
              ...register("city", {
                required: "This field is required",
              }),
            },
          }}
          placeholder="ex. Toronto"
        />

        <TextField
          id="outlined-full-name"
          label="Province"
          variant="outlined"
          name="province"
          error={Boolean(errors.province)}
          helperText={errors.province && errors.province.message}
          InputProps={{
            inputProps: {
              ...register("province", {
                required: "This field is required",
              }),
            },
          }}
          placeholder="ex. Ontario"
        />

        <TextField
          id="outlined-full-name"
          label="Postal Code"
          variant="outlined"
          name="postalCode"
          error={Boolean(errors.postalCode)}
          helperText={errors.postalCode && errors.postalCode.message}
          InputProps={{
            inputProps: {
              ...register("postalCode", {
                required: "This field is required",
              }),
            },
          }}
          placeholder="ex. N8J 2J9"
        />

        <div className="mt-5">
          <Button
            name="Next"
            disabled={
              errors?.address ||
              errors?.city ||
              errors?.province ||
              errors?.postalCode ||
              data?.address === "" ||
              data?.city === "" ||
              data?.province === "" ||
              data?.postalCode === ""
            }
            onClick={(e) => {
              e.preventDefault();
              setPage("guardian information");
            }}
          />
          <p
            className="text-sm text-center mt-4 cursor-pointer hover:underline text-grey"
            onClick={() => setPage("studentInformation")}
          >
            Back
          </p>
        </div>
      </div>
    </div>
  );
};

export default styled(Address)`
  .form {
    display: flex;
    flex-direction: column;
    gap: 35px;
    max-width: 600px;
    width: 100%;
    margin-bottom: 50px;

    .Mui-error {
      margin: 0;
    }

    > div {
      font-family: "Open Sans";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 22px;
      color: #191919;

      label {
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 22px;
        color: #191919;
        opacity: 0.8;
      }

      > input {
        background: #f4f4f4;
        border-radius: 5px;
        border: none;
        box-sizing: border-box;
        height: 40px;
        width: 100%;
        font-family: "Open Sans";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 22px;
        color: black;
        opacity: 0.8;

        ::placeholder {
          font-family: "Open Sans";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;
          color: #707070;
          opacity: 0.8;
        }
      }
    }

    @media only screen and (min-width: 700px) {
      padding: 50px;
      box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.1);
    }
  }
`;
