import React from "react";
import styled from "styled-components";

import tree from "./tree.png";

const Registration = ({ className }) => {
  return (
    <div className={className}>
      <img src={tree} alt="" />

      <div>
        <h2>2024 KCSA Summer Camp.</h2>

        <p>
          Enrich your child's summer with KCSA Summer Camp, where they'll grow
          through cultural discovery, outdoor adventures, and new friendships.
        </p>
        <p>
          Nurturing their connection to Korean heritage, our camp provides a
          supportive and engaging environment for unforgettable memories.
        </p>
      </div>
    </div>
  );
};

export default styled(Registration)`
  padding-left: 40%;
  margin-right: 10px;
  position: relative;
  min-height: 350px;
  height: 80vw;
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 50px;

  img {
    position: absolute;
    top: 10px;
    z-index: 1;
    left: -25vw;
    height: 80vw;
    min-height: 350px;
    max-width: 100%;
    object-fit: cover;
  }

  h2 {
    font-style: normal;
    font-weight: 700;
    font-size: 6vw;
    margin: 0;
    margin-bottom: 2.5vw;
  }

  p {
    font-size: 12px;
    margin-bottom: 10vw;
  }

  p:nth-of-type(1) {
    margin-bottom: 2.5vw;
  }

  div {
    position: absolute;
    bottom: 0;
    padding-right: 10px;
  }

  @media only screen and (min-width: 360px) {
    h2 {
      font-size: 5.5vw;
    }

    p {
      font-size: 3.2vw;
    }
  }

  @media only screen and (min-width: 400px) {
    h2 {
      font-size: 5vw;
    }

    p {
      font-size: 3vw;
    }
  }

  @media only screen and (min-width: 768px) {
    margin-right: 25px;
    height: 70vw;
    padding-left: 40%;

    img {
      height: 70vw;
    }
    h2 {
      font-size: 4.2vw;
    }

    p {
      font-size: 2.5vw;
    }

    div {
      padding-right: 60px;
    }
  }

  @media only screen and (min-width: 1000px) {
    max-height: 800px;

    img {
      max-height: 800px;
    }

    h2 {
      font-size: 40px;
    }
    p {
      font-size: 30px;
      max-width: 580px;
    }

    img {
      margin-left: auto;
      margin-right: auto;
      left: -350px;
      right: 0;
    }

    div {
      position: absolute;
      margin-left: auto;
      margin-right: auto;
      left: 0;
      right: -450px;
      bottom: -75px;
      padding: 0;
      height: fit-content;
      width: 580px;
      height: 600px;
    }

    h2,
    p,
    p:nth-of-type(1) {
      margin-bottom: 25px;
    }
  }
`;
