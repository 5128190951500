import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { supabase } from "../../server";
import { useSelector } from "react-redux";
import { formatTimestamp } from "./helper";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button from "../common-component/button";
import { useNavigate } from "react-router-dom";

const parse = require("html-react-parser");

const Entry = ({ className }) => {
  const navigate = useNavigate();

  const { announcementId } = useParams();

  const [content, setContent] = useState();

  // Checking if user is logged in
  const authenticated =
    JSON.parse(localStorage.getItem("sb-lqzahgakgtrswulxeedj-auth-token"))?.user
      ?.aud === "authenticated";

  const deletePost = async () => {
    const { error } = await supabase
      .from("announcement")
      .delete()
      .eq("id", content.id);

    navigate("/announcements");
  };

  useEffect(() => {
    (async () => {
      const { data, error } = await supabase
        .from("announcement")
        .select()
        .eq("id_new", announcementId);

      setContent(data[0]);
    })();
  }, []);

  return content ? (
    <div className={`pt-[100px] mx-auto px-4 max-w-[1200px] ${className}`}>
      <Carousel showArrows={true} showIndicators={false}>
        {content.images.map((image) => (
          <div>
            {["mp4", "quicktime", ".mov"].includes(
              image.split(".").slice(-1)[0]
            ) ? (
              <video
                src={`https://storage.googleapis.com/kcsa/announcement-image/${content.id}-${image}`}
                alt=""
                className="w-full object-cover max-h-[500px] rounded-sm"
                // autoPlay
                controls
              />
            ) : (
              <img
                src={`https://storage.googleapis.com/kcsa/announcement-image/${content.id}-${image}`}
                alt=""
                className="w-full object-cover max-h-[500px] rounded-sm"
              />
            )}
          </div>
        ))}
      </Carousel>

      <h1 className="font-bold text-xl">{content.title}</h1>

      <p className="text-[10px] mb-6 mt-1">
        {formatTimestamp(content.timestamp)}
      </p>

      <p className="text-sm">{content.entry && parse(content.entry)}</p>

      {authenticated && (
        <Button
          name="Delete Post"
          className="mt-8 m-auto"
          onClick={deletePost}
        />
      )}
    </div>
  ) : (
    ""
  );
};

export default styled(Entry)`
  .thumbs.animated {
    display: none;
  }

  .control-arrow {
    width: 25%;
  }
`;
