import React from "react";
import advocacyAndSupport from "../../images/about-us/advocacy-and-support.png";
import collabCommunication from "../../images/about-us/collab-communication.png";
import culturalEvents from "../../images/about-us/cultural-events.png";
import koreanLanguageClasses from "../../images/about-us/korean-language-classes.png";
import scholarship from "../../images/about-us/scholarship.png";
import volunteerOpportunities from "../../images/about-us/volunteer-opportunities.png";

const ProgramsServices = () => {
  const programs = [
    { img: koreanLanguageClasses, text: "Korean language classes" },
    { img: culturalEvents, text: "Cultural events & activities" },
    { img: scholarship, text: "Scholarship for Korean-Canadian students" },
    {
      img: advocacyAndSupport,
      text: "Advocacy and support for Korean-Canadian students",
    },
    {
      img: collabCommunication,
      text: "Collaboration & communication platform for schools",
    },
    {
      img: volunteerOpportunities,
      text: "Volunteer opportunities for students and teachers",
    },
  ];

  return (
    <div className="max-w-[1400px] mx-auto py-8">
      <div className="container mx-auto px-4">
        <h2 className="text-xl mb-6 font-bold mt-8 text-center">
          Our programs and services include:
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {programs.map((program, index) => (
            <div
              key={index}
              className="panel bg-white shadow-lg rounded-lg p-4 hover:shadow-xl transition-shadow duration-300"
            >
              <div className="flex items-center">
                <img src={program.img} alt={program.text} className="mr-4" />
                <p className="text-sm text-left">{program.text}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProgramsServices;
