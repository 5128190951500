import { configureStore } from "@reduxjs/toolkit";
import userReducer from "../slices/userSlice";
import announcementReducer from "../slices/announcementSlice";
import languageReducer from "../slices/languageSlice";
import screenReducer from "../slices/screenSlice";
import studentRegistrationReducer from "../slices/studentRegistrationSlice";

export const store = configureStore({
  reducer: {
    auth: userReducer,
    announcement: announcementReducer,
    language: languageReducer,
    screen: screenReducer,
    studentRegistration: studentRegistrationReducer,
  },
});
